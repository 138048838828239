<ng-container *ngIf="contact$ | async as contact; else noCommercial">
  <div class="profil">{{contact.prenom}} {{contact.nom}}</div>
  <div class="commercial">
    <div><span class="logoTelNoir"></span>{{contact.numeroTelephone}}</div>
    <div><span class="logoMailNoir"></span>{{contact.adresseEmail}}</div>
  </div>
</ng-container>
<ng-template #noCommercial>
  <p class="m-5">Pas de contact commercial pour le moment.</p>
</ng-template>
