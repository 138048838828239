<div class="row title mb-3">
  <div class="col">
    {{item.points}} points
    <svg class="bi" (click)="collapsed = !collapsed">
      <use xlink:href="assets/bootstrap-icons.svg#caret-down-fill" *ngIf="collapsed"/>
      <use xlink:href="assets/bootstrap-icons.svg#caret-up-fill" *ngIf="!collapsed"/>
    </svg>
  </div>
</div>
<div class ="row" [ngbCollapse]="collapsed">
  <div class="col-12 col-lg-auto" *ngIf="boschFamilies.length > 0">
    <div class="bosch">
      <div class="d-flex flex-column flex-lg-row align-items-center">
        <div class="col-auto align-self-start align-self-lg-center mb-3 mb-lg-0">
          <img class="d-none d-lg-block logoBosch" src="/assets/images/bosch-vertical.svg" alt="Bosch">
          <img class="d-block d-lg-none logoBoschResponsive" src="/assets/images/bosch.svg" alt="Bosch">
        </div>
         <app-scoring-family *ngFor="let family of boschFamilies" [family]="family"></app-scoring-family>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-auto" *ngIf="elmFamilies.length > 0">
    <div class="elm">
      <div class="d-flex flex-column flex-lg-row align-items-center">
        <div class="col-auto align-self-start align-self-lg-center mb-3 mb-lg-0">
          <img class="d-none d-lg-block logoElm" src="/assets/images/elm-leblanc-vertical.svg" alt="elm.leblanc">
          <img class="d-block d-lg-none logoElmResponsive" src="/assets/images/elm-leblanc.svg" alt="elm.leblanc">
        </div>
        <app-scoring-family *ngFor="let family of elmFamilies" [family]="family"></app-scoring-family>
      </div>
    </div>
  </div>

</div>
