import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';
import { shareReplay, map } from 'rxjs/operators';
import { OidcSecurityService } from 'angular-auth-oidc-client';
//import { OidcSecurityService } from 'src/app/shared/lib/angular-auth-oidc-client/src/lib/angular-auth-oidc-client';

@Component({
  selector: 'app-principle',
  templateUrl: './principle.component.html',
  styleUrls: ['./principle.component.scss']
})
export class PrincipleComponent implements OnInit {

  private installateurs = ['IPP', ,'IPC','Installateur'];
  private sav = ['Stabe', 'SAV'];

  private type$: Observable<string>;
  isInstallateur$: Observable<boolean>;
  isSav$: Observable<boolean>;

  private clubStatus$ : Observable<string>;
  isAPlusPlus$ : Observable<boolean>;

  isAuth$: Observable<boolean>;

  constructor(private userService: UserService, private oidcSecurityService: OidcSecurityService) { }

  ngOnInit(): void {
    this.type$ = this.userService.getType().pipe(shareReplay(1));
    this.isInstallateur$ = this.type$.pipe(
      map(type => this.installateurs.includes(type)),
      shareReplay(1)
    );
    this.isSav$ = this.type$.pipe(
      map(type => this.sav.includes(type)),
      shareReplay(1)
    );

    this.clubStatus$ = this.userService.getClubStatus().pipe(shareReplay(1));
    this.isAPlusPlus$ = this.clubStatus$.pipe(
      map(status => status == "A++"),
      shareReplay(1)
    )

    this.isAuth$ = this.oidcSecurityService.isAuthenticated$.pipe(map(result => result.isAuthenticated));
    //.pipe(
    //   shareReplay(1)
    // );
    // this.isAuth$ = of(true);
  }
}

