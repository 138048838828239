import { Component, OnInit, Input } from '@angular/core';
import { ScoringFamily, ScoringBrand } from '../models/scoring-family.model';
import { ScoringItem } from '../models/scoring-item.model';

@Component({
  selector: 'app-scoring-category',
  templateUrl: './scoring-category.component.html',
  styleUrls: ['./scoring-category.component.scss']
})
export class ScoringCategoryComponent implements OnInit {

  @Input() item: ScoringItem;

  boschFamilies: ScoringFamily[];
  elmFamilies: ScoringFamily[];

  collapsed = false;

  constructor() { }

  ngOnInit(): void {
    this.boschFamilies = this.item.familles.filter(f => f.marque === ScoringBrand.Bosch);
    this.elmFamilies = this.item.familles.filter(f => f.marque === ScoringBrand.Elm);
  }

}
