import { Component, OnInit } from '@angular/core';
import { EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
//import { EventTypes, OidcSecurityService, PublicEventsService } from 'src/app/shared/lib/angular-auth-oidc-client/src/lib/angular-auth-oidc-client';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PermissionService } from './core/services/permission.service';
import { StorageService } from './core/services/storage.service';

const RETURNURL_KEY = 'returnurl';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  title = 'app';
  private storage: Storage;

  constructor(private eventService: PublicEventsService, private permissionService: PermissionService,
    private oidcSecurityService: OidcSecurityService, private router: Router, private storageService: StorageService) {
    this.storage = this.storageService.get();
  }

  ngOnInit(): void {
    this.eventService
      .registerForEvents()
      .pipe(
        filter((notification) => notification.type === EventTypes.UserDataChanged),
        switchMap((notification) => {
          return this.permissionService.handlePermissions(notification.value != null)
        })
      )
      //.subscribe((perm) => console.log('permissions: ' + perm));

    this.oidcSecurityService
      .checkAuth()
      .subscribe(
        (response) => {
          if (response.isAuthenticated) {
            this.permissionService.handlePermissions(true).subscribe();
            this.navigateToStoredEndpoint();
          } else {
            let refreshToken = this.oidcSecurityService.getRefreshToken();
            if (!response.isAuthenticated && !!refreshToken) {
              this.oidcSecurityService.forceRefreshSession().subscribe((result) => {
                this.permissionService.handlePermissions(true).subscribe();
              });
            }
          }
        },
        (error) => console.error(error),
        () => this.storage.removeItem(RETURNURL_KEY),
      );
  }

  private navigateToStoredEndpoint() {
    const path = this.storage.getItem(RETURNURL_KEY);

    if (path == null || this.router.url === path) {
      return;
    }

    if (path.toString().includes('/unauthorized')) {
      this.router.navigate(['/']);
    } else {
      this.router.navigate([path]);
    }
  }

  refreshSession() {
    this.oidcSecurityService.forceRefreshSession().subscribe();
  }
}
