import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PrincipleComponent } from './principle/principle.component';
import { ContactComponent } from './contact/contact.component';
import { ScoringComponent } from './scoring/scoring.component';
import { AccountCreationComponent } from './account-creation/account-creation.component';
import { AuthorizeGuard } from '../shared/guards/authorize.guard';

const routes: Routes = [
  { path: 'home',
    component: HomeComponent,
    pathMatch: 'full',
    //canActivate: [UserCreationGuard],
  },
  {
    path: 'principle',
    component: PrincipleComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
    canActivate: [AuthorizeGuard]
  },
  {
    path: 'scoring',
    component: ScoringComponent,
    canActivate: [AuthorizeGuard]
  },
  { path: 'register',
    component: AccountCreationComponent,
    canActivate: [AuthorizeGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
