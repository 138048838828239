<main class="home">
    <section
        style="font-family: 'BoschSansRegular',sans-serif;font-size: 14px;line-height: 1;color: #005691;margin:5px;">
        <div class="container">
            <div class="title" style="font-size:30px;">Conditions générales de vente</div>

            <div class="content row" style="margin-top:30px;">
                <section id="" class="section fs-two-column-text color--black theme--light bgcolor--white">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-6">
                                <p><b>Conditions Générales de Vente ci-après les « Conditions »</b></p>
                                <p>Les présentes Conditions annulent et remplacent toutes les conditions antérieures.
                                    Elles sont susceptibles de faire l’objet de modifications ou d’une réédition
                                    complète notamment en cas de changement significatif du contexte règlementaire ou
                                    législatif. Toute modification des présentes Conditions est automatiquement
                                    opposable au Client dans un délai de huit semaines suivant sa notification par tout
                                    moyen (courrier postal, courrier électronique, télécopie, etc…).</p>
                                <p></p>
                                <p><b>1. Généralités</b></p>
                                <p>Les présentes Conditions régissent uniquement les relations entre le fabricant,
                                    ci-après désigné le “Fournisseur” et le client auquel il vend son matériel ou ses
                                    services, ci-après désigné le “Client”. Le Fournisseur livrera tout Client chargé de
                                    revendre les appareils de chauffage, de rafraîchissement et de confort thermique, à
                                    tout type de clientèle, sans restriction. Pour des raisons de sécurité et de
                                    protection de l’image de marque du Fournisseur, le Client s’engage à promouvoir
                                    auprès de ses propres clients, l’installation ou le remplacement des appareils du
                                    Fournisseur par un installateur professionnel (code NAF 43.22A ou 43.22 Bp ou 43.21
                                    B). Elles constituent le socle unique de la négociation commerciale, conformément
                                    aux termes de l’article L.441-1 du Code de Commerce. Les termes « marchandise » ou «
                                    matériel » ou « produit » signifient tous produits finis, accessoires, pièces
                                    détachées commercialisés par le Fournisseur.</p>
                                <p>Le Fournisseur se réserve le droit de refuser la vente de certaines marchandises si
                                    leur destination finale directe ou indirecte est située hors UE / AELE. Ces
                                    Conditions s’appliquent en France métropolitaine, Corse comprise (hors DOM-TOM).</p>
                                <p>Toute commande implique l’adhésion sans réserve aux Conditions ci-dessous qui
                                    régissent seules les ventes et prestations du Fournisseur, à l’exclusion des
                                    conditions d’achat du Client ou de tout autre document émanant de lui, dont les
                                    termes sont inopposables au Fournisseur sauf accord exprès contraire.</p>
                                <p>Les commandes du Client doivent obligatoirement être transmises par écrit (courrier
                                    électronique, interface informatique ou bien fax). Le Fournisseur n’acceptera aucune
                                    commande verbale ou par téléphone. Pour être recevable, une commande doit être
                                    chiffrée et comporter la référence article, la quantité et l’adresse de livraison.
                                </p>
                                <p>Toute commande n’est valable et le contrat de vente ou de prestation de services
                                    n’est réputé formé que lors de son acceptation expresse par écrit sous la forme d’un
                                    accusé de réception de commande établi par le Fournisseur, ou à défaut, par le seul
                                    fait de l’exécution de la commande par le Fournisseur, y compris lorsque les offres
                                    sont effectuées par les représentants ou les membres du personnel du Fournisseur.
                                    Pour être valable, toute convention verbale précédent ou accompagnant la conclusion
                                    du contrat nécessite la confirmation écrite du Fournisseur. Des extraits des
                                    Conditions Générales de Vente du Fournisseur sont annexés à ses tarifs et sont
                                    vérifiables sur ses sites internet
                                    (https://www.elmleblanc.fr/fr/fr/pied-de-page/cgv/ et
                                    https://www.pro.bosch-climate.fr/conditions-generales-de-vente) dont les liens sont
                                    aussi mentionnés sur les factures du Fournisseur. Le Client est ainsi réputé avoir
                                    eu connaissance des Conditions dès l’origine des relations entre les parties. </p>
                                <p>Le Fournisseur se réserve le droit, sous réserve d’en informer préalablement le
                                    Client, de refuser, diminuer ou fractionner toute commande qui présente un caractère
                                    manifestement anormal par rapport au volume de commande habituel du Client ou
                                    susceptible d’occasionner une désorganisation des services industriels ou
                                    logistiques du Fournisseur. Un tel refus ou modification ne pourra donner lieu à
                                    réclamation ni dédommagement.</p>
                                <p></p>
                                <p><b>2. Gestion des Comptes Clients</b></p>
                                <p>Le Fournisseur subordonne l’ouverture de compte ou l’application de ses Conditions à
                                    l’obtention des documents comptables, financiers, juridiques et, le cas échéant, de
                                    garanties.</p>
                                <p>Toute ouverture de compte n’est valable qu’après accord de la Direction du
                                    Fournisseur. L’exécution d’une première commande ne dérogera pas à cette règle. Tout
                                    octroi de délai de paiement, dans la limite des plafonds fixés par les dispositions
                                    de l’article L.441-10 du Code de commerce, est subordonné à une analyse financière
                                    préalable.</p>
                                <p></p>
                                <p></p>
                                <p><b>3. Franco de Port et Commandes</b></p>
                                <p>Les commandes de produits finis et accessoires sont franco de port si un montant
                                    minimum de 3000€ HT et hors éco-participation est atteint. En deçà de ce montant,
                                    des frais de port à hauteur de 35€ HT seront appliqués sans accord préalable du
                                    Client et pour chacune des commandes reçues chez le Fournisseur.</p>
                                <p>Les commandes de pièces détachées sont franco de port si un montant minimum de 250€
                                    HT et hors éco-participation est atteint. En deçà de ce montant, des frais de port à
                                    hauteur de 20€ HT seront appliqués sans accord préalable du Client et pour chacune
                                    des commandes reçues chez le Fournisseur. </p>
                                <p>En cas de commande mixte (pièces détachées et produits finis), les deux règles de
                                    minimum de commande s’appliquent de manière distincte. D’autre part, le Fournisseur
                                    se réserve le droit de regrouper plusieurs commandes d’un même Client dans les
                                    livraisons sans que cela remette en cause les seuils individuels de commandes.</p>
                                <p>Les montants de commande minimum rappelés ci-dessus pour l’application du franco
                                    s’apprécient pour une commande livrée à une seule adresse de livraison.</p>
                                <p></p>
                                <p><b>4. Produits / Services / Prix </b></p>
                                <p>Le Fournisseur se réserve le droit, sous réserve d’en informer préalablement le
                                    Client dans un délai raisonnable, de stopper la commercialisation d’un produit ou
                                    d’un service et de proposer ou non sa substitution par un autre produit ou service
                                    similaire à un prix identique ou différent.</p>
                                <p>Les prix du Fournisseur s’entendent hors taxes, hors éco-participation (ou DEEE) si
                                    applicable et hors droits et taxes en sus qui seraient à la charge du Client. En cas
                                    de revente, les prix du Fournisseur ne doivent en aucun cas être considérés comme
                                    prix de vente au public, même indicatifs, les Clients étant seuls responsables de la
                                    fixation de leur prix de revente, qu’ils déterminent librement.</p>
                                <p>Le Fournisseur se réserve le droit de modifier ses prix, notamment en cas de
                                    variations des conditions économiques impactant ses marchandises, après en avoir
                                    informé le Client par tous moyens dans un délai raisonnable. Les renseignements
                                    portés sur les catalogues et tarifs du Fournisseur sont donnés à titre indicatif. Ce
                                    dernier, se réserve le droit d’apporter sans préavis des modifications de
                                    disposition, de forme, de dimensions ou de matière à ses matériels dont les
                                    illustrations, les photographies, les descriptions ou les schémas d’installation
                                    figurent sur ses imprimés de publicité ou sur tout autre support de communication.
                                </p>
                                <p>Les prix appliqués sont ceux en vigueur le jour de la commande. Tout versement à la
                                    commande est un acompte définitivement acquis au Fournisseur, même en cas de
                                    résiliation d’un contrat. Sauf accord préalable entre les parties, toute livraison
                                    du matériel catalogué est facturée au prix mentionné sur l’accusé de réception de
                                    commande. Le cas échéant, le Fournisseur répercutera intégralement toute variation à
                                    la hausse ou à la baisse du prix de ses marchandises qu’il pourrait subir de la part
                                    de ses fournisseurs entre la date de réception de la commande et la livraison ainsi
                                    que la variation des parités monétaires.</p>
                                <p>Sauf dispositions contraires (notamment en cas de transports spécifiques ou urgents),
                                    les prix du Fournisseur comprennent l’emballage et le transport. L’éventuelle
                                    majoration pour commande livrée au comptoir ou de prestation de services, fait
                                    l’objet de conditions particulières. </p>
                                <p>Les marchandises voyagent avec des supports palettes dites ‘perdues’. Le Fournisseur
                                    ne procède donc pas à l’échange ou au remboursement de palettes.</p>
                                <p></p>
                                <p>5. Délais de Livraison</p>
                                <p>Les délais de livraison sont donnés à titre indicatif et ne constituent pas
                                    l’engagement pour le Fournisseur de livrer la marchandise à la date mentionnée. Le
                                    point de départ du délai de livraison est la date inscrite sur la confirmation de la
                                    commande du Client. La livraison de produits en « set » ou en « kits » se fera
                                    lorsque l’ensemble des marchandises composant le set ou le kit sera disponible ;
                                    aucune livraison ou retour fractionné ne sera donc possible pour ces produits.</p>
                                <p>Les délais de livraison standards du Fournisseur sont les suivants :</p>
                                <p>- pour les produits finis et accessoires en stock = 5 jours</p>
                                <p>- pour les commandes « chantiers » = 10 jours</p>
                                <p>- pour les pièces détachées en stock = 48 heures</p>
                                <p>- pour les pièces détachées sur fabrication = 5 jours (liste disponible sur demande).
                                </p>
                                <p>En cas de livraison souhaitée par le Client en deçà de ces délais, le Fournisseur
                                    proposera un mode de livraison expresse avec tarification spéciale à la charge du
                                    Client comme indiqué ci-dessous : </p>
                                <p>Livraison J+2 ouvrables (en messagerie express) – commande reçue avant 12h00
                                    –Livraison toute France métropolitaine hors Corse &amp; Îles et hors dimensions
                                    spéciales, sous réserve de disponibilité :</p>
                                <p></p>
                                <p>- Expédition &lt; 100kg = 100€ HT</p>
                                <p>- Expédition &gt;101kg &lt; 500kg = 350€ HT</p>
                                <p>- Expédition &gt; 501kg (max 800kg) = 700€ HT</p>
                                <p></p>
                                <p>Livraison J+1 ouvrable (par coursier dédié) –commande reçue avant 12h00 </p>
                                <p>Livraison toute France métropolitaine hors Corse &amp; Îles et hors dimensions
                                    spéciales, sous réserve de disponibilité :</p>
                                <p>- Expédition &lt; 200kg et/ou 2 palettes = 650€ HT</p>
                                <p></p>
                                <p>Cependant, le Fournisseur s’efforcera de respecter les délais de livraison demandés
                                    par le Client.</p>
                                <p>Le dépassement raisonnable des délais de livraison indiqués par le Fournisseur ne
                                    pourra, quelle qu’en soit la cause, entraîner l’annulation de commande, refus ou
                                    retour de marchandise par le Client sans accord préalable écrit du Fournisseur.</p>
                                <p>En cas de retard de livraison, le Client ne pourra pas engager la responsabilité du
                                    Fournisseur pour la perte de chiffre d’affaires, l’atteinte à l’image de marque ou
                                    la perte de bénéfices.</p>
                                <p>Si, alors que la commande a déjà été préparée, le Client demande à différer la
                                    livraison de plus de quinze (15) jours, le Fournisseur se réserve le droit de
                                    facturer au Client pour tout mois entamé des frais de stockage correspondant à 0,5%
                                    du prix de la marchandise à livrer.</p>
                                <p>Le cas échéant, le traitement de la commande est conditionné au versement préalable
                                    et effectif de l’acompte prévu. </p>
                                <p>Compte tenu de la saisonnalité des produits, le Fournisseur n’admet aucune pénalité
                                    ou indemnité de quelque ordre que ce soit pour retard de livraison ou d’annulation
                                    de la commande.</p>
                                <p></p>
                                <p><b>6. Expéditions</b></p>
                                <p>Nonobstant la clause de réserve de propriété stipulée ci-après, les marchandises du
                                    Fournisseur voyagent et sont déchargées aux risques et périls du Client. La
                                    livraison est réputée effectuée lors du départ des usines ou des entrepôts du
                                    Fournisseur.</p>
                                <p></p>
                                <p><b>7. Paiements</b></p>
                                <p>Sauf dispositions contraires expressément acceptées par le Fournisseur, les paiements
                                    du Client ont lieu à la date précisée sur la facture, soit quarante-cinq jours fin
                                    de mois suivant la date de facturation de la marchandise. Les acomptes sont
                                    toutefois payés au comptant.</p>
                                <p>A défaut de dispositions contraires convenues entre les parties, les travaux de
                                    réparation, d’entretien, de même que les marchandises supplémentaires ou livrées en
                                    cours de montage sont payables au comptant, net et sans escompte. </p>
                                <p>Les paiements doivent intervenir au siège social du Fournisseur. Seul l’encaissement
                                    effectif et intégral par le Fournisseur est considéré comme un paiement. Sauf
                                    dispositions contraires stipulées par écrit, aucun escompte pour paiement anticipé,
                                    ristourne ou rabais ne sera consenti.</p>
                                <p>En aucun cas, le Client ne peut s’octroyer un escompte de sa propre initiative. Le
                                    Client ne sera pas autorisé à compenser les factures du Fournisseur avec ses propres
                                    factures. </p>
                                <p>Les paiements ne peuvent en aucune hypothèse être retardés par le Client, y compris
                                    en cas de litige sur la qualité du matériel.</p>
                                <p>Tout retard de paiement d’une échéance quelconque entraîne de plein droit et sans
                                    qu’un rappel soit nécessaire :</p>
                                <p>- la déchéance du terme pour toutes commandes exécutées et non réglées, même si elles
                                    ont donné lieu à l’émission d’effets,</p>
                                <p>- la suspension de l’exécution des commandes en cours,</p>
                                <p>- l’annulation de tous les avoirs et/ou réduction de prix acquis(es) et/ou à établir,
                                </p>
                                <p>En cas de dépassement du délai de paiement le Fournisseur pourra réclamer,
                                    automatiquement et sans délai, des intérêts de retard égaux à trois fois le taux
                                    légal en vigueur ainsi que le paiement d’une indemnité forfaitaire pour frais de
                                    recouvrement fixée à 40€ ou d’une indemnité supérieure, si cette majoration se
                                    justifie et ce, nonobstant le droit du Fournisseur de demander réparation de tout
                                    autre préjudice.</p>
                                <p>Par ailleurs, le Fournisseur se réserve le droit de refuser toute commande du Client
                                    en cas de retard de paiement, de litige financier ou si la situation financière du
                                    Client semble l’exiger, sous réserve du respect par le Fournisseur des règles
                                    applicables aux procédures collectives. Le Fournisseur pourra éventuellement exiger
                                    le paiement comptant des commandes du Client dans les cas précisés ci-dessus.</p>
                                <p>En cas de changement dans la situation du Client, notamment en cas de décès,
                                    d’incapacité, de dissolution ou de modification de sa société, d’hypothèque de ses
                                    immeubles, de vente ou nantissement de son fonds de commerce ainsi qu’en cas de
                                    défaut d’exécution par le Client d’une quelconque de ses obligations, notamment au
                                    titre d’une précédente commande, le Fournisseur se réserve le droit, même après
                                    exécution partielle d’une commande, d’exiger un paiement d’avance ou des garanties
                                    ou d’annuler, le cas échéant, le solde de la commande.</p>
                                <p></p>
                                <p><b>8. Livraisons, Contrôles des Livraisons, Réclamations et Retours</b></p>
                                <p>Le Client doit vérifier les marchandises à la livraison. Ce contrôle doit notamment
                                    porter sur la qualité, les quantités, les emballages, l’état et les références des
                                    marchandises et leur conformité par rapport à la commande et au bordereau de
                                    livraison.</p>
                                <p>En cas d’anomalie constatée à la livraison des marchandises, il est de la
                                    responsabilité du Client de notifier toutes les réserves qu’il jugera utiles auprès
                                    du transporteur responsable et directement sur le bon de livraison ou sur tout
                                    support présenté par le transporteur (terminaux portables, par exemple) en présence
                                    du livreur. </p>
                                <p>En cas de non-présentation du bordereau de livraison, le Client devra exiger ce
                                    document auprès du livreur, contrôler minutieusement sa réception et porter des
                                    réserves pour absence de bordereau de livraison ou bien refuser la prise en charge
                                    de la livraison.</p>
                                <p>Dans le cas où le Client n’aurait pas émis de réserves précises sur le bordereau de
                                    livraison ou autre support du transporteur, le Client a trois jours ouvrables
                                    maximum pour notifier au transporteur concerné par acte extrajudiciaire ou par
                                    lettre recommandée avec AR, le cachet de la poste faisant foi, sa protestation
                                    motivée sous peine de perdre tout recours contre le transporteur et/ou le
                                    Fournisseur. A défaut, aucune réclamation postérieure ne pourra être prise en
                                    considération par le Fournisseur.</p>
                                <p>Dans tous les cas, le bordereau de livraison du Fournisseur doit être vérifié et
                                    émargé par le Client et demeure le seul document faisant foi pour le transfert de
                                    responsabilités et en cas de litige entre le Client et le Fournisseur. Par
                                    conséquent, l’émargement de la lettre de voiture, le terminal portatif du
                                    transporteur ou la CMR du transporteur n’est pas suffisant.</p>
                                <p>D’une manière générale, la responsabilité du Fournisseur se limite strictement à
                                    l’obligation de remplacer la marchandise non conforme, à l’exclusion de tous
                                    dommages et intérêts.</p>
                                <p>Les retours de marchandises ne seront acceptés qu’après accord préalable et écrit du
                                    Fournisseur avec un numéro d’enregistrement lié à ce retour. Le fait pour le
                                    Fournisseur d’avoir consenti à un retour pour une marchandise déterminée ne confère
                                    pas au Client le droit d’obtenir un retour pour d’autres marchandises, mêmes
                                    identiques.</p>
                                <p>Les marchandises retournées voyagent aux frais et risques du Client en port payé par
                                    ce dernier. Le retour doit s’effectuer au lieu indiqué par le Fournisseur ou, à
                                    défaut de précisions, à l’adresse de son siège social.</p>
                                <p>Les marchandises retournées ou reprises à titre commercial doivent être en parfait
                                    état de conservation, dans leur conditionnement et emballage d’origine et ne
                                    présenter aucun signe de démontage, d’installation ou d’utilisation. Le retour des
                                    marchandises endommagées ou déjà installées fera l’objet d’un remballage et
                                    étiquetage adéquat à la charge du Client permettant le transport.</p>
                                <p>Toute demande de retour ou reprise de produits finis sous garantie par le Client doit
                                    comporter les informations figurant dans la fiche retour du Fournisseur pour la
                                    prise en charge notamment l’ensemble des numéros de série concernés et la facture
                                    d’origine.</p>
                                <p>Le retour donne lieu à l’établissement d’un avoir correspondant au prix des matériels
                                    concernés, déduction faite d’une retenue forfaitaire au titre du traitement
                                    administratif du retour.</p>
                                <p>Si l’état de la marchandise retournée le justifie, une décote sera appliquée sur
                                    l’avoir émis par le Fournisseur.</p>
                                <p></p>
                                <p><b>9. Réserve de Propriété</b></p>
                                <p>Le Fournisseur se réserve l’entière propriété des produits, accessoires et/ou pièces
                                    détachées vendus jusqu’au paiement de l’intégralité du prix et de ses taxes, TVA,
                                    éco-participation et frais de services associés le cas échéant, étant précisé que
                                    seul l’encaissement effectif du prix vaudra paiement. Ne constitue pas un paiement
                                    la remise d’une lettre de change ou d’un autre titre créant une simple obligation de
                                    payer.</p>
                                <p>Cependant, les risques du transport ayant été transférés au Client dès l’expédition,
                                    ce dernier s’engage à assurer la marchandise et à indiquer à son assureur que le
                                    Fournisseur en reste le propriétaire. Le Fournisseur accorde au Client, à titre
                                    révocable, le droit de revendre les marchandises. Ce droit cessera de plein droit et
                                    sans formalité en cas de non-paiement total ou partiel d’une échéance quelconque.
                                </p>
                                <p>Le Client est dans l’obligation d’informer le Fournisseur par lettre recommandée avec
                                    accusé de réception, sans délai, de toute tentative de saisie. Il s’engage en toutes
                                    circonstances à préserver le droit de propriété du Fournisseur.</p>
                                <p>A défaut de paiement total ou partiel des factures des marchandises à leur échéance,
                                    celles-ci devront être restituées au Fournisseur à première demande écrite aux
                                    frais, risques et périls du Client qui s’y oblige, et ce, huit jours après mise en
                                    demeure par lettre recommandée avec accusé de réception adressée au Client restée en
                                    tout ou partie sans effet pendant ce délai. Les acomptes déjà versés resteront
                                    acquis au Fournisseur à titre de premiers dédommagements.</p>
                            </div>
                            <div class="col-sm-6">
                                <p><b>10. Garanties</b></p>
                                <p>La garantie commerciale du Fournisseur est subordonnée à la remise à l’Utilisateur
                                    d’un Certificat de Conformité pour les produits concernés (certificat prévu par
                                    l’arrêté du 2 août 1977 modifié), ce qui n’affecte pas la garantie légale.</p>
                                <p>La garantie commerciale du Fournisseur est subordonnée à l’installation du matériel
                                    conformément aux règles de l’art, aux normes en vigueur et aux prescriptions de nos
                                    notices techniques. Nos conditions de garanties figurent sur les bons de garantie de
                                    nos produits et dans l’édition complète de nos Conditions Générales de Vente
                                    incluses dans nos tarifs. Nos appareils bénéficient d’une garantie pièces détachées,
                                    dans le cadre de la législation en vigueur, pendant deux (2) ans à compter de la
                                    date de réception provisoire pour les chantiers et de la date de la mise en service
                                    pour les particuliers. Les dates ci-dessus doivent être, dans tous les cas, portées
                                    à notre connaissance ou à celle de notre réseau d’après-vente par les soins de
                                    l’installateur au moyen du volet joint au certificat de garantie.</p>
                                <p>Mise en œuvre de la garantie : celle-ci consiste pour le Fournisseur à remédier à ses
                                    frais et en toute diligence, aux défauts dont est atteint le matériel. Le
                                    Fournisseur a le choix des moyens à employer pour satisfaire à cette obligation -
                                    réparations, modifications, remplacements. Les frais de transport des matériels, de
                                    déplacement du personnel et de main-d’œuvre sont exclus de la garantie. Dans
                                    l’hypothèse la réparation s’avèrerait impossible, le Fournisseur s’engage à
                                    rembourser le produit à hauteur de son prix d’achat effectif. </p>
                                <p>Restrictions : la garantie ne s’applique pas aux conséquences de l’usure normale et
                                    ne concerne pas les pièces d’usure. Par ailleurs, la garantie est exclue, notamment
                                    si les conditions suivantes n’ont pas été respectées :</p>
                                <p>- stockage à l’abri de l’humidité et des intempéries, </p>
                                <p>- mise en œuvre et installation conformes aux règles de l’art,</p>
                                <p>- bonne qualité de l’eau utilisée, tant au niveau du circuit du chauffage que du
                                    circuit sanitaire,</p>
                                <p>- entretien périodique réalisé par un professionnel qualifié et, le cas échéant,
                                    réparations ou remplacements conformes aux règles de l’art et aux prescriptions
                                    techniques,</p>
                                <p>- utilisation conforme à l’usage auquel le produit est destiné et aux prescriptions
                                    des notices d’utilisation.</p>
                                <p>Obligations du Client :</p>
                                <p>Le Client communiquera à ses propres clients les conditions de la présente garantie.
                                    Pour que la garantie puisse s’appliquer, le Client veillera à ce que le Fournisseur
                                    soit informé, sans retard et par écrit, des défauts imputés au matériel et à ce que
                                    lui soient fournies toutes justifications quant à la réalité de ceux-ci. Il veillera
                                    également à ce que le Fournisseur bénéficie de toute facilité pour procéder à la
                                    constatation de ces défauts et pour y porter remède le cas échéant. </p>
                                <p>La garantie cesse en cas de détérioration de l’appareil lors d’une réparation ou
                                    d’une modification par une intervention autre que celle du Fournisseur ou d’une
                                    entreprise de son réseau d’après-vente. Les dispositions de la garantie ne sont pas
                                    exclusives du bénéfice au profit du Client de la garantie légale pour défauts ou
                                    vices cachés qui s’applique en tout état de cause dans les conditions de l’article
                                    1641, 1648 et 2232 du Code Civil, pris ensemble, ni de la garantie de conformité
                                    telles que résultant des articles L. 217-4 à L. 217-12 du Code de la consommation.
                                </p>
                                <p>Le Client demeure libre de proposer les garanties commerciales de son choix à ses
                                    propres clients, sous sa responsabilité. En sa qualité de vendeur, le Client assume
                                    également les garanties légales mises à sa charge.</p>
                                <p></p>
                                <p>La réparation et les remplacements effectués dans le cadre de la garantie ne font pas
                                    courir une nouvelle durée de garantie et ne prolongent pas la garantie initiale.</p>
                                <p>Extension de garantie</p>
                                <p>Produits de Marque Elm </p>
                                <p>La garantie contre tout vice de fabrication de deux (2) ans pièces de rechange est
                                    automatiquement étendue à trois (3) ans pour la cuve des ballons intégrés ainsi que
                                    celle des ballons de stockage (BAL et BIL), sous réserve que l’installation ait été
                                    réalisée dans les règles de l’art, que l’eau utilisée ne soit pas trop adoucie et
                                    que l’anode de protection ait fait l’objet d’une vérification périodique (effectuée
                                    au minimum une fois par an). </p>
                                <p>Les corps de chauffe des chaudières résidentielles à condensation sont garantis cinq
                                    ans contre tout vice de fabrication, sous réserve que l’installation ait été mise en
                                    service par le Fournisseur ou par une Station Technique Agréée par le Fournisseur et
                                    si un entretien annuel est réalisé chaque année par le Fournisseur ou par une
                                    Station Technique Agréée par le Fournisseur. La garantie cesse en cas de
                                    détérioration de l’appareil lors d’une réparation ou d’une modification par une
                                    intervention autre que celle du Fournisseur ou d’une entreprise de son réseau
                                    d’après-vente. Les dispositions de la garantie ne sont pas exclusives du bénéfice au
                                    profit du Client de la garantie légale pour défauts ou vices cachés qui s’applique
                                    en tout état de cause dans les conditions de l’article 1641 et suivants du Code
                                    Civil. </p>
                                <p>Produits de Marque Bosch </p>
                                <p> La garantie contre tout vice de fabrication de deux (2) ans pièces de rechange est
                                    automatiquement étendue à trois (3) ans pour la cuve des ballons intégrés ainsi que
                                    celle des ballons de stockage, sous réserve que l’installation ait été réalisée dans
                                    les règles de l’art, que l’eau utilisée ne soit pas trop adoucie et que l’anode de
                                    protection ait fait l’objet d’une vérification périodique (effectuée au minimum une
                                    fois par an). La garantie de deux ans pièces est automatiquement étendue à cinq ans
                                    pour le compresseur des pompes à chaleur sous réserve que l’installation ait été
                                    mise en service par le Fournisseur ou par une Station Technique Agréée par le
                                    Fournisseur. Elle est également étendue à cinq (5) ans pour la cuve des chauffe-eau
                                    thermodynamiques monoblocs. Les corps de chauffe des chaudières résidentielles à
                                    condensation sont garantis cinq (5) ans contre tout vice de fabrication, sous
                                    réserve que l’installation ait été mise en service par le Fournisseur ou par une
                                    Station Technique Agréée par le Fournisseur et si un entretien annuel est réalisé
                                    chaque année par le Fournisseur ou par une Station Technique Agréée par le
                                    Fournisseur. La garantie de deux ans pièces de rechange de notre gamme de
                                    climatisation résidentielle est automatiquement étendue à trois ans pour l’ensemble
                                    des pièces de rechange et à cinq ans pour les compresseurs. Sur cette gamme, la main
                                    d’œuvre est également garantie la première année sous réserve que l’installation ait
                                    été mise en service par le Fournisseur ou par une Station Technique Agréée par le
                                    Fournisseur. La garantie cesse en cas de détérioration de l’appareil lors d’une
                                    réparation ou d’une modification par une intervention autre que celle du Fournisseur
                                    ou d’une entreprise de son réseau d’après- vente. Les dispositions de la garantie ne
                                    sont pas exclusives du bénéfice au profit du Client de la garantie légale pour
                                    défauts ou vices cachés qui s’applique en tout état de cause dans les conditions de
                                    l’article 1641 et suivants du Code Civil.</p>
                                <p>Reproduction des articles L.217-4, L.217-5, L.217-12, L.217-16 du Code de la
                                    consommation </p>
                                <p>Article L217-4</p>
                                <p>Le vendeur livre un bien conforme au contrat et répond des défauts de conformité
                                    existant lors de la délivrance.</p>
                                <p>Il répond également des défauts de conformité résultant de l'emballage, des
                                    instructions de montage ou de l'installation lorsque celle-ci a été mise à sa charge
                                    par le contrat ou a été réalisée sous sa responsabilité.</p>
                                <p></p>
                                <p>Article L217-5</p>
                                <p>Le bien est conforme au contrat :</p>
                                <p>1° S'il est propre à l'usage habituellement attendu d'un bien semblable et, le cas
                                    échéant : </p>
                                <p>- s'il correspond à la description donnée par le vendeur et possède les qualités que
                                    celui-ci a présentées à l'acheteur sous forme d'échantillon ou de modèle ;</p>
                                <p>- s'il présente les qualités qu'un acheteur peut légitimement attendre eu égard aux
                                    déclarations publiques faites par le vendeur, par le producteur ou par son
                                    représentant, notamment dans la publicité ou l'étiquetage ;</p>
                                <p>2° Ou s'il présente les caractéristiques définies d'un commun accord par les parties
                                    ou est propre à tout usage spécial recherché par l'acheteur, porté à la connaissance
                                    du vendeur et que ce dernier a accepté.</p>
                                <p></p>
                                <p>Article L217-12 </p>
                                <p></p>
                                <p>L'action résultant du défaut de conformité se prescrit par deux ans à compter de la
                                    délivrance du bien.</p>
                                <p></p>
                                <p>Article L217-16 </p>
                                <p>Lorsque l'acheteur demande au vendeur, pendant le cours de la garantie commerciale
                                    qui lui a été consentie lors de l'acquisition ou de la réparation d'un bien meuble,
                                    une remise en état couverte par la garantie, toute période d'immobilisation d'au
                                    moins sept jours vient s'ajouter à la durée de la garantie qui restait à courir.</p>
                                <p>Cette période court à compter de la demande d'intervention de l'acheteur ou de la
                                    mise à disposition pour réparation du bien en cause, si cette mise à disposition est
                                    postérieure à la demande d'intervention.</p>
                                <p></p>
                                <p>Reproduction des articles 1641 et 1648 premier alinéa du Code civil : </p>
                                <p>Article 1641</p>
                                <p>Le vendeur est tenu de la garantie à raison des défauts cachés de la chose vendue qui
                                    la rendent impropre à l'usage auquel on la destine, ou qui diminuent tellement cet
                                    usage que l'acheteur ne l'aurait pas acquise, ou n'en aurait donné qu'un moindre
                                    prix, s'il les avait connus.</p>
                                <p>Article 1648 alinéa 1</p>
                                <p>L'action résultant des vices rédhibitoires doit être intentée par l'acquéreur dans un
                                    délai de deux ans à compter de la découverte du vice. </p>
                                <p></p>
                                <p><b>11. Responsabilités</b></p>
                                <p>La responsabilité du Fournisseur est strictement limitée à :</p>
                                <p>- son obligation de garantie ainsi définie ; </p>
                                <p>- versement des dommages et intérêts, résultant de l’inexécution des obligations
                                    prévues dans les présentes Conditions, que dans la mesure où il en résulte un
                                    préjudice avéré pour le Client qui est la conséquence immédiate et directe du défaut
                                    d’exécution de nos obligations contractuelles</p>
                                <p>En aucun cas le Fournisseur ne sera tenu à aucune indemnisation au titre de dommages
                                    immatériels ou indirects tels que notamment, mais sans s’y limiter, manque à gagner,
                                    perte d’utilisation, perte de jouissance, perte de revenu, réclamation de tiers,
                                    immobilisations, préjudice d’image, etc. </p>
                                <p></p>
                                <p><b>12. Propriété Intellectuelle</b></p>
                                <p>Sauf dispositions contraires stipulées par écrit, le Fournisseur reste titulaire de
                                    toute information, de tout concept (idées, stratégies, méthodologie…), de toute
                                    spécification, de tout document (photographies, documentations techniques, schémas
                                    d’encastrements, vues éclatées, etc.) de tout objet (modèles, échantillons,
                                    spécimens…) ainsi que des brevets et du savoir-faire mis en œuvre dans le cadre de
                                    fourniture par le Fournisseur de marchandises (produits finis, pièces détachées
                                    et/ou accessoires) ou de prestations. </p>
                                <p>Le Client ne dispose d’aucun droit sur les marques et autres signes distinctifs du
                                    Fournisseur, autre que le simple droit de les utiliser en vue de l’exposition et de
                                    la commercialisation des marchandises. Dans tous les cas, le Client s’abstient de
                                    porter préjudice à l’image du Fournisseur ou de ses marques.</p>
                                <p></p>
                                <p><b>13. Confidentialité </b></p>
                                <p>Aucune information commerciale ou technique émanant du Fournisseur ou d’une société
                                    affiliée au Fournisseur, y compris les caractéristiques résultant de tout objet ou
                                    logiciel éventuellement remis, tout savoir-faire ou expérience, ne doit être
                                    divulguée à des tiers tant que, preuves à l’appui, elle n’est pas connue du grand
                                    public ou que le Fournisseur n’ait pas fait savoir qu’elle peut être divulguée par
                                    le Client.</p>
                                <p></p>
                                <p>Par ailleurs, le Client ne divulguera les informations confidentielles du
                                    Fournisseur, qu’auprès de personnes habilitées, tenues au secret et ayant besoin de
                                    les utiliser. Ces informations demeurent la propriété exclusive du Fournisseur ou
                                    d’une société affiliée. Sans l’accord écrit et préalable du Fournisseur, de telles
                                    informations ne doivent pas être reproduites ou utilisées à des fins commerciales ou
                                    industrielles. A sa demande, toutes les informations émanant du Fournisseur (y
                                    compris les copies ou enregistrements éventuellement réalisés) ainsi que tous les
                                    objets prêtés doivent lui être restitués sans délai dans leur intégralité ou être
                                    détruits. </p>
                                <p></p>
                                <p>Le Fournisseur se réserve tous les droits sur les informations visées à l´article 12
                                    (y compris les droits d´auteur et le droit de déposer des demandes de délivrance de
                                    titres de propriété industrielle, tels que brevets, dessins et modèles, droits de
                                    protection de semi-conducteurs, etc.).</p>
                                <p></p>
                                <p><b>14. Clause Résolutoire</b></p>
                                <p>En cas d’inexécution par le Client d’une quelconque de ses obligations, notamment
                                    celle de payer le matériel à la date convenue au titre des présentes et huit jours
                                    après une mise en demeure par lettre recommandée avec accusé de réception, restée
                                    infructueuse en tout ou partie pendant ce délai, la vente sera résolue de plein
                                    droit, dès l’envoi par le Fournisseur d’une seconde lettre recommandée avec demande
                                    d’avis d’accusé de réception : les acomptes partiels, éventuellement versés par le
                                    Client, étant acquis au Fournisseur à titre de premiers dommages et intérêts et sans
                                    préjudice de tous autres.</p>
                                <p></p>
                                <p><b>15. Cessation du Contrat</b></p>
                                <p>En cas de changement de sa situation, notamment en cas d’incapacité, de dissolution,
                                    de modification de la société, de cession, de mise en nantissement de cession ou
                                    apport en société de son fonds de commerce ou de son matériel par le Client, de
                                    changement dans la détention de la majorité de son capital, de fusion, de scission
                                    ou apport partiel d’actif, le Client devra en informer sans délai et par écrit le
                                    Fournisseur. Le Client ne pourra céder ou transférer à une personne physique ou
                                    morale, l’un quelconque de ses droits, l’une de ses obligations en vertu du présent
                                    contrat sans l’approbation préalable et écrite du Fournisseur. En cas de non-respect
                                    de cette obligation par le Client, le Fournisseur pourra même après exécution
                                    partielle d’une commande, et sans sommation, résilier immédiatement le contrat sans
                                    que sa responsabilité ne soit engagée, et sans indemnité au profit du Client, sans
                                    préjudice de tous dommages et intérêts qui seraient réclamés à ce dernier.</p>
                                <p></p>
                                <p><b>16. Clause Pénale</b></p>
                                <p>En cas d’action judiciaire en recouvrement de la créance du Fournisseur, ce dernier
                                    se réserve le droit de réclamer devant la juridiction compétente à titre de clause
                                    pénale une somme égale à 15% du montant dû en principal, avec un minimum de 762,25 €
                                    hors taxes pour tenir compte des honoraires et frais irrépétibles découlant de
                                    ladite action.</p>
                                <p></p>
                                <p><b>17. Résiliation </b></p>
                                <p>En cas d’inexécution par le Client d’une quelconque de ses obligations au titre des
                                    présents et huit jours après une mise en demeure par lettre recommandée avec demande
                                    d’avis de réception, restée infructueuse en tout ou partie pendant ce délai, la
                                    vente sera résiliée de plein droit, immédiatement et sans formalité, les acomptes
                                    partiels éventuellement versés par le Client étant acquis à notre Société à titre de
                                    premiers dommages et intérêts sans préjudice de tous autres.</p>
                                <p>En cas de résiliation, le Client doit permettre au Fournisseur d’accéder aux produits
                                    dont nous avons conservé la propriété en vertu de l’Article 9 des présentes
                                    Conditions. Après notification au Client, le Fournisseur peut soit en réclamer la
                                    restitution ou la revente immédiate afin d’obtenir le paiement du prix de ces
                                    produits.</p>
                                <p>Les dispositions qui figurent dans le présent Article s’appliquent sans préjudice des
                                    droits et revendications prévues par la loi.</p>
                                <p></p>
                                <p><b>18. Force majeure</b></p>
                                <p>Les obligations de livraison du Fournisseur sont suspendues de plein droit et sans
                                    formalité et, de manière générale, sa responsabilité est dégagée en présence d’un
                                    cas de force majeure. Les cas de force majeure sont : « incendies, inondations,
                                    grêle, tornade, tremblements de terre, éruptions, ou autres phénomènes
                                    météorologiques, émeutes, guerres, mouvements populaires, lock-out, bris ou mise
                                    hors service de machines, actes délictuels, vandalisme ou brigandage, embargos,
                                    restrictions d’importation ou d’exportation, blocages en douane, grèves, défaillance
                                    de nos fournisseurs, pénuries de matières premières ou composants, restriction de
                                    courant, interruption de transport, fermeture de frontières, réquisitions ou autres
                                    mesures administratives, interdictions de circuler, fermetures des voies et/ou
                                    routes ou accès, déchargent le Fournisseur de toute obligation, sans aucune
                                    indemnité possible.</p>
                                <p>Le Fournisseur s’engage à informer le Client, par tous moyens à sa convenance, de la
                                    survenance d’un tel évènement.</p>
                                <p></p>
                                <p><b>19. Juridiction</b></p>
                                <p>En cas de contestation, seul le Tribunal de Commerce de Bobigny sera compétent, même
                                    en cas de pluralité de défendeurs ou d’appel en garantie pour tout litige.</p>
                                <p>Les domiciliations d’effets et les acceptations de règlement par le Fournisseur
                                    n’emportent ni novation ni dérogation à cette attribution de juridiction.</p>
                                <p>Aucune clause contraire du Client ne peut déroger à cette clause attributive de
                                    juridiction, sauf acceptation expresse et par écrit de la part du Fournisseur.</p>
                                <p></p>
                                <p><b>20. Loi Applicable</b></p>
                                <p>La loi applicable aux relations entre le Fournisseur et le Client est la loi
                                    française.</p>
                                <p></p>
                                <p><b>21. Clause de Renonciation</b></p>
                                <p>Le fait que le Fournisseur ne se prévale pas, à un moment donné, de l’une quelconque
                                    des présentes Conditions, ne peut être interprété comme valant renonciation à se
                                    prévaloir ultérieurement de l’une quelconque desdites Conditions.</p>
                                <p></p>
                                <p><b>22. Clause de Sauvegarde</b></p>
                                <p>Si une quelconque stipulation des présentes est jugée non-écrite et sans effet,
                                    illégale ou inapplicable, les stipulations restantes demeureront pleinement en
                                    vigueur.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </section>
</main>