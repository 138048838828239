<main>
  <div class="row howWork">

    <div class="col-1 d-none d-lg-block"></div>
    <div class="col-12 col-lg-4">
      <div class="titre my-5 ml-3 ml-lg-0">Le Club, comment ça marche ?</div>
      <div class="texte my-auto">
        <p>Pour profiter de vos avantages, vous devez simplement <b>créer votre compte</b> et <b>déclarer vos
            installations</b> de produits Bosch et/ou elm.leblanc sur le site <a
            href="https://www.elmlecube.fr/">www.elmlecube.fr</a> - rubrique Club Degré+.</p>
        <p>Un <b>seul compte principal</b> (Chef d'entreprise) peut être créé par entreprise. Ce compte est celui qui a
          accès à la boutique
          et à l’historique des déclarations.</p>
        <p>Des comptes annexes (Salarié) reliés au compte principal peuvent être créés, mais ils permettront uniquement
          de
          déclarer des produits, sans accès à la boutique ni visibilité sur l’historique des déclarations.</p>
        <p>Vos déclarations d'installation donnent droit à des points, à échanger dans la boutique.</p>
        <p>En accédant au Club, vous vous engagez <b>selon votre statut fidélité</b> à respecter les <b>engagements
            définis</b> à compter de votre date d’inscription.</p>
        <ng-container *ngIf="isAuth$ | async">
          <p *ngIf="isInstallateur$ | async"><b>Votre statut fidélité sera réévalué à <span class="red">chacun de vos
                anniversaires</span></b> en tant
            que
            membre du Club. Cette réévaluation se fera en fonction du <b>respect et de l’atteinte de vos engagements et
              objectifs.</b></p>
          <p>Retrouvez ci-dessous le détails de vos avantages et engagements<span *ngIf="isInstallateur$ | async"> selon
              votre statut</span> :</p>
        </ng-container>
      </div>
    </div>
    <div class="col-1 d-none d-lg-block"></div>
    <div class="col-12 col-lg-4 my-auto">
      <a class="no-style" href="assets/REGLEMENT_GLOBAL_CLUB_DEGRE_PLUS_2023.pdf" target="_blank">
        <div class="reglement w-75" *ngIf="isAuth$ | async">Télécharger le règlement</div>
      </a>
    </div>
    <div class="col-1"></div>
  </div>

  <div class="row howWorkInfo mx-auto">

    <!-- Vue connecté -->
    <ng-container *ngIf="isAuth$ | async">

      <!-- Vue IPP -->
      <ng-container *ngIf="isInstallateur$ | async">
        <div class="col-11 offset-1 titre my-5">Les statuts fidélité</div>

        <div class="col-xl-3 col-lg-8 col-xs-12 mx-auto d-flex flex-column mb-5">
          <div class="howWorkSquare mx-auto">
            <div class="square">
              <div class="squareImage"><img src="assets/images/statutA.png" /></div>
            </div>
          </div>
          <div class="statut mt-4">Statut <b>A</b></div>
          <div class="statusEncadre w-100">
            <div class="statusEncadreWhite">
              <div class="engagement">
                <div class="titre">Vos engagements</div>
                <div class="mx-auto">
                  <ul class="texte">
                    <li>Déclarer au moins <b>5 produits</b> Bosch et/ou elm.leblanc par <b>périodes de 24 mois</b></li>
                  </ul>
                </div>
              </div>
              <div class="avantage">
                <div class="titre">Vos avantages</div>
                <div class="mx-auto">
                  <ul class="texte">
                    <li>Offre de <b>50 points</b> anniversaires*</li>
                    <li>Accès à la boutique <b>Statut A</b></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-lg-8 col-xs-12 mx-auto d-flex flex-column mb-5">
          <div class="howWorkSquare mx-auto">
            <div class="square">
              <div class="squareImage1"><img src="/assets/images/statutAp.png" /></div>
            </div>
          </div>

          <div class="statut mt-4">Statut <b>A+</b></div>
          <div class="statusEncadre w-100">
            <div class="statusEncadreWhite">
              <div class="engagement">
                <div class="titre">Vos engagements</div>
                <div class="mx-auto">
                  <ul class="texte">
                    <li>Déclarer à hauteur de <b>15 000 points</b> minimum par an**</li>
                    <li>Être inscrit à l'<b>E-academy</b></li>
                    <li>Réaliser <b>1 formation</b>, lors de la sortie de nouveaux produits et selon vos besoins</li>
                    <li>Être qualifié <b>RGE</b></li>
                  </ul>
                </div>
              </div>
              <div class="avantage">
                <div class="titre">Vos avantages</div>
                <div class="mx-auto">
                  <ul class="texte">
                    <li>Offre de <b>200 points</b> anniversaires*</li>
                    <li>Accès à la boutique <b>Statut A+</b></li>
                    <li><b>Accès privilégié</b> à la <b>hotline technique</b> produits</li>
                    <li><b>Barème bonifié</b> x2,5</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="isAPlusPlus$ | async">
          <div class="col-xl-3 col-lg-8 col-xs-12 mx-auto d-flex flex-column mb-5">
            <div class="howWorkSquare mx-auto">
              <div class="square">
                <div class="squareImage2"><img src="/assets/images/statutAp_p.png" /></div>
              </div>
            </div>
            <div class="statut mt-4">Statut <b>A++</b></div>
            <div class="statusEncadre w-100">
              <div class="statusEncadreWhite">
                <div class="engagement">
                  <div class="titre">Vos engagements</div>
                  <div class="mx-auto">
                    <ul class="texte">
                      <li>Réservé aux <b>IPP***</b> (avec respect du contrat signé)</li>
                      <li>Réservé aux <b>IPC***</b> (avec respect du contrat signé)</li>
                      <li>Déclarer à hauteur de <b>5 000 points</b> minimum par an**</li>
                      <li>Être inscrit à l'<b>E-academy</b></li>
                      <li>Réaliser <b>1 formation</b>, lors de la sortie de nouveaux produits et selon vos besoins</li>
                      <li>Être qualifié <b>RGE</b></li>
                    </ul>
                  </div>
                </div>
                <div class="avantage">
                  <div class="titre">Vos avantages</div>
                  <div class="mx-auto">
                    <ul class="texte">
                      <li>Offre de <b>400 points</b> anniversaires*</li>
                      <li>Accès à la boutique <b>Statut A++</b></li>
                      <li><b>Accès privilégié</b> à la <b>hotline technique</b> produits</li>
                      <li><b>Barème bonifié</b> x2,5</li>
                      <li><b>Identifié partenaire Certifié</b> sur nos sites de marques</li>
                      <li>Participation aux <b>tables rondes</b></li>
                      <li><b>Visites d'usine</b></li>
                      <li>Accès aux <b>avantages Bosch-Siemens</b></li>
                      <li><b>Article dédié</b> à votre entreprise <b>sur nos réseaux sociaux</b><small> (sur
                          demande)</small></li>
                      <li>Accès à l’outil <b>Lead Management</b><small> (génération de contacts clients qualifiés)</small></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <!-- Vue Stabe -->
      <ng-container *ngIf="isSav$ | async">
        <div class="col-xl-3 col-lg-8 col-xs-12 mx-auto d-flex flex-column mb-5">
          <div class="howWorkSquare mx-auto">
            <div class="square">
              <div class="squareImage1"><img src="/assets/images/statutAp.png" /></div>
            </div>
          </div>
          <div class="statut mt-4">Statut <b>STABE</b></div>
          <div class="statusEncadre w-100">
            <div class="statusEncadreWhite">
              <div class="engagement">
                <div class="titre">Vos engagements</div>
                <div class="mx-auto">
                  <ul class="texte">
                    <!-- <li><b>Déclarer au moins 5 produits</b> Bosch et/ou elm.leblanc par <b>périodes de 24 mois</b></li> -->
                    <li>Déclarer vos <b>mises en services</b></li>
                    <li>Déclarer vos <b>installations</b></li>
                    <li>Être inscrit à <b>l'E-academy</b></li>
                    <li>Réaliser <b>1 formation</b>, lors de sorties de nouveaux produits et selon vos besoins</li>
                  </ul>
                </div>
              </div>
              <div class="avantage">
                <div class="titre">Vos avantages</div>
                <div class="mx-auto">
                  <ul class="texte">
                    <li>Offre de <b>200 points</b> anniversaires*</li>
                    <li>Accès à la boutique <b>dédiée STABE**</b></li>
                    <li><b>Accès privilégié</b> à la <b>hotline technique</b> produits</li>
                    <li><b>Identifié Partenaire Certifié</b> sur nos sites de marque</li>
                    <li><b>Visites d'usine</b></li>
                    <li>Participation aux <b>tables rondes</b></li>
                    <li>Vos <b>avantages STABE** contractuels</b></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Légende -->
      <div *ngIf="isAuth$ | async" class="legende col-11 offset-1">
        <p>*En année glissante à compter de la date d'inscription du membre.</p>
        <p *ngIf="isInstallateur$ | async">**Par année civile soit du 1er janvier au 31 décembre</p>
        <ng-container *ngIf="isInstallateur$ | async">
          <p *ngIf="isAPlusPlus$ | async">***IPP : Installateur Partenaire Performance </p>
          <p *ngIf="isAPlusPlus$ | async">***IPC : Installateur Partenaire Performance </p>
        </ng-container>
        <p *ngIf="isSav$ | async">**STABE : Service Technique Agréé Bosch elm.leblanc</p>
      </div>

    </ng-container>

    <div class="public col-10 offset-1" *ngIf="!(isAuth$ | async)">
      <!-- <div class="titre">Vos engagements</div> -->
      <div class="texte">
        Veuillez créer votre compte ou vous connecter pour prendre connaissance de vos engagements personnalisés et
        connaître les avantages liés à votre statut.
      </div>
    </div>
  </div>

</main>
