import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationPaths, QueryParameterNames } from 'src/app/core/api-authorization.constants';
import { OidcSecurityService } from 'angular-auth-oidc-client';
//import { OidcSecurityService } from 'src/app/shared/lib/angular-auth-oidc-client/src/lib/angular-auth-oidc-client';
import { PermissionService } from 'src/app/core/services/permission.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeGuard implements CanActivate {
  constructor(private router: Router, private oidcSecurityService: OidcSecurityService, private permissionService: PermissionService) {
  }
  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return zip(this.oidcSecurityService.isAuthenticated$, this.permissionService.hasPermissions$).pipe(
      map(([result, hasPerm]) => {
        console.log('AuthorizeGuard, canActivate isAuthorized: ' + result.isAuthenticated);

        if (!result.isAuthenticated) {
          this.handleAuthorization(result.isAuthenticated, state);
          return false;
        }

        return true;
      })
    );
  }


  private handleAuthorization(isAuthenticated: boolean, state: RouterStateSnapshot) {
    if (!isAuthenticated) {
      this.router.navigate(ApplicationPaths.LoginPathComponents, {
        queryParams: {
          [QueryParameterNames.ReturnUrl]: state.url
        }
      });
    }
  }
}
