import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Organisation } from 'src/app/shared/models/organisation.model';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getOrganisation(siret: string): Observable<Organisation> {
     return this.http.get<any>(this.baseUrl + 'api/organisation/' + siret);
  }
}
