<header>
  <div class="d-flex flex-row justify-content-between align-items-center bg-white header cube-application" #spacer>
    <div class="icons left">
      <a [href]="(cubeUrl | async)"><img src="assets/images/header-cube.svg" title="Le Cube"></a>
      <a [routerLink]="['/']"><img src="assets/images/header-home.svg" title="Accueil"></a>
    </div>
    <div class="headerLogoClub">
      <img class="w-100" src="assets/images/LogoClubdegré03HD.png" alt="Club Degré +">
    </div>
    <!-- <div class="title">
      Club Degré +
    </div> -->
    <div class="icons">
      <app-login-menu></app-login-menu>
    </div>
  </div>

  <img src="assets/images/frise_1.svg" class="frise"/>

  <app-header-menu *ngIf="!router.url.includes('/administration')"></app-header-menu>
</header>
