import { Component, OnInit } from '@angular/core';
import { ScoringService } from '../services/scoring.service';
import { ScoringItem } from '../models/scoring-item.model';
import { Observable, of } from 'rxjs';
import { mapTo, startWith, catchError, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-scoring',
  templateUrl: './scoring.component.html',
  styleUrls: ['./scoring.component.scss']
})
export class ScoringComponent implements OnInit {

  scoringItems$: Observable<ScoringItem[]>;

  isLoading$: Observable<boolean>;
  error$: Observable<any>;

  constructor(private scoringService: ScoringService) { }

  ngOnInit(): void {
    this.scoringItems$ = this.scoringService.getItems().pipe(shareReplay(1));

    this.isLoading$ = this.scoringItems$.pipe(mapTo(false), catchError(() => of(undefined)), startWith(true));

    this.error$ = this.scoringItems$.pipe(
        mapTo(false),
        catchError((e) => of(e))
    );
  }

}
