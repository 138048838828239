import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { defaultIfEmpty, map, startWith, take, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  private cachedDeclaration$: Observable<string>;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getCubeUrl(): Observable<string> {
     return this.http.get<any>(this.baseUrl + 'api/url/cube').pipe(
       map(res => res.url),
       //shareReplay(1)
     );
  }

  getSignupUrl(): Observable<string> {
    return this.http.get<any>(this.baseUrl + 'api/url/signup').pipe(
      map(res => res.signupUrl),
    );
  }

  getProfileUrl(): Observable<string> {
    return this.http.get<any>(this.baseUrl + 'api/url/profile').pipe(
      map(res => res.profileUrl),
    );
  }

 get declarationUrl$() {
  if (!this.cachedDeclaration$) {
    this.cachedDeclaration$ = this.getDeclarationUrl().pipe(
      shareReplay(1)
    );
  }

  return this.cachedDeclaration$;
}

 private getDeclarationUrl(): Observable<string> {
  return this.http.get<any>(this.baseUrl + 'api/url/declaration').pipe(
    map(res => res.declarationUrl.replace(/\/$/, '') + '/login'),
  );
 }
}
