<main>
    <div class="d-flex justify-content-center flex-wrap">
        <div class="col-12 col-lg-5">
            <div class="contact">
                <div class="titre">Contact Club Degré +</div>
                <div class="texte">Pour toute <b>demande autre que les commandes passées</b> dans la boutique, merci de
                    nous écrire à l'adresse email ci-dessous. En cas de problème technique, veuillez joindre à votre
                    message une <b>copie d'écran</b> du problème rencontré.</div>
                <div class="info"><span class="logoMailRouge"></span>communication.contact@fr.bosch.com</div>
            </div>
        </div>
        <ng-container *ngIf="clubStatus$ | async as clubStatus">
            <ng-container *ngIf="clubStatus == 'A+' || clubStatus == 'A++'">
                <div class="col-12 col-lg-5">
                    <div class="contact">
                        <div class="titre">Votre hotline technique dédiée</div>
                        <div class="texte">En tant que membre du Club, vous bénéficiez d'un accès à une hotline
                            technique
                            dédiée, pour répondre à toutes vos questions techniques d'après-vente et vous aider en cas
                            de
                            dépannage produit. La hotline est accessible au numéro ci-dessous, de 8h30 à 12h30 et de
                            13h30 à
                            17h30 du lundi au vendredi. Afin de pouvoir accéder à ce service, il vous sera demandé lors
                            de
                            votre
                            appel de vous identifier avec votre numéro d'adhérent Club Degré +.</div>
                        <div class="info"><span class="logoTelRouge"></span>09 70 82 40 22</div>
                        <div class="infoSmall">Numéro non surtaxé</div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <div class="col-12 col-lg-5">
            <div class="contact">
                <div class="titre">Contact boutique</div>
                <div class="texte">Pour toute demande concernant la boutique, sur <b>l'un des articles</b> ou sur
                    <b>l'une
                        de vos commandes</b>, contactez-nous par téléphone au numéro suivant du lundi au vendredi de 9h
                    à
                    12h30 et de 13h30 à 17h30.
                </div>
                <div class="info"><span class="logoTelRouge"></span>01 30 10 00 33</div>
            </div>
        </div>
        <div class="col-12 col-lg-5">
            <div class="contact">
                <div class="titre">Votre contact commercial</div>
                <app-sales-contact></app-sales-contact>
            </div>
        </div>
    </div>
</main>