<div class="headerMenu">
  <div class="headerMenuLogo container-fluid m-0 p-1 px-lg-3">
    <div class="d-flex flex-row align-items-lg-center align-items-start w-100">
      <div class="d-none d-lg-block mr-2 headerLogoBosch">
        <img class="w-100" src="/assets/images/bosch.svg" alt="Bosch">
      </div>
      <div class="mx-0 px-0">
        <!-- <div class="menu mx-0 px-0" #stickyMenu [class.sticky]="sticky"> -->
        <div class="menu mx-0 px-0" #stickyMenu>
          <nav class="navbar navbar-expand-lg navbar-light">
            <button class="navbar-toggler border-0 p-0" type="button" aria-label="Toggle navigation"
              (click)="isMenuCollapsed = !isMenuCollapsed">
              <!-- <span class="navbar-toggler-icon"></span> -->
              <svg version="1.1" id="Icon_x5F_contour" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 192 192" xml:space="preserve">
                <path d="M160,60H32v-8h128V60z M160,92H32v8h128V92z M160,132H32v8h128V132z" />
              </svg>
            </button>
            <div class="collapse navbar-collapse" [ngbCollapse]="isMenuCollapsed">
              <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                <li class="nav-item">
                  <a class="nav-link d-flex" routerLink="/home" routerLinkActive="active"
                    (click)="isMenuCollapsed = true">Accueil<img class="ml-2" src="/assets/images/add.svg"></a>
                </li>
                <li class="nav-item" *ngIf="isAuthAndValid$ | async">
                  <a class="nav-link d-flex" routerLink="/account" routerLinkActive="active"
                    (click)="isMenuCollapsed = true">Mon
                    compte Degré+<img class="ml-2" src="/assets/images/add.svg"></a>
                </li>
                <li class="nav-item">
                  <a class="nav-link d-flex" routerLink="/principle" routerLinkActive="active"
                    (click)="isMenuCollapsed = true">Comment ça marche ?<img class="ml-2"
                      src="/assets/images/add.svg"></a>
                </li>
                <li class="nav-item" *ngIf="isAuthAndValid$ | async">
                  <a class="nav-link d-flex" routerLink="/scoring" routerLinkActive="active"
                    (click)="isMenuCollapsed = true">Barème des produits<img class="ml-2"
                      src="/assets/images/add.svg"></a>
                </li>
                <li class="nav-item" *ngIf="isAuthAndValid$ | async">
                  <a class="nav-link d-flex" routerLink="/shop" routerLinkActive="active"
                    (click)="isMenuCollapsed = true">Boutique<img class="ml-2" src="/assets/images/add.svg"></a>
                </li>
                <li class="nav-item" *ngIf="isAuthAndValid$ | async">
                  <a class="nav-link d-flex" routerLink="/promotions" routerLinkActive="active"
                    (click)="isMenuCollapsed = true">Promotions<img class="ml-2" src="/assets/images/add.svg"></a>
                </li>
                <li class="nav-item" *ngIf="isAuthAndValid$ | async">
                  <a class="nav-link d-flex" routerLink="/contact" routerLinkActive="active"
                    (click)="isMenuCollapsed = true">Mes
                    contacts<img class="ml-2" src="/assets/images/add.svg"></a>
                </li>
                <li class="nav-item center ml-3" *ngIf="isLoading$ | async">
                  <div class="dot-flashing"></div>
                </li>
                <!-- <li class="nav-item" *ngIf="isAuth$ | async">
              <a class="nav-link" href="#">Promotions</a>
            </li> -->
              </ul>
              <!-- <div class="form-inline my-2 my-lg-0" *ngIf="isAuth$ | async"> -->

            </div>
          </nav>
        </div>
      </div>
      <div class="ml-auto d-flex flex-row align-items-center">
        <div class="d-none d-lg-block px-0 mr-2 headerLogoElm">
          <img class="w-100" src="/assets/images/elm-leblanc.svg" alt="elm.leblanc">
        </div>
        <div class="mt-1 my-lg-0 ml-2 mr-2 mr-lg-0" *ngIf="isAuthAndValid$ | async">
          <div class="shopping" routerLink="/shop/cart">
            <span class="badge badge-pill badge-danger cart-count" *ngIf="(cart$ | async) as cart">
              <ng-container *ngIf="cart.count > 0">{{cart.count}}</ng-container>
            </span>
          </div>
          <!-- <div class="search my-2 my-sm-0"></div> -->
        </div>
        <div *ngxPermissionsOnly="['Admin', 'Commercial', 'DirecteurRegional']" class="mr-2 ml-3 back-office" routerLink="/administration">
          <svg class="w-100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
            <path
              d="M156.74 124.04a24.088 24.088 0 0 0 2.439-5.83 23.838 23.838 0 0 0-2.398-18.209 23.826 23.826 0 0 0-8.809-8.78c.014-.404.028-.809.028-1.221 0-14.336-11.663-26-26-26S96 75.664 96 90c0 1.295.107 2.583.297 3.857a23.82 23.82 0 0 0-7.476 11.932c-1.642 6.13-.82 12.529 2.306 18.042L80.41 138.12l-3.68 11.044-7.711 3.856L63.529 164l6 12h20.736l24.07-40.117a23.905 23.905 0 0 0 9.662-3.101l.018-.011a24.088 24.088 0 0 0 5.774 2.408c1.211.324 2.432.541 3.655.675l7.269 14.689 7.287 7.186v7.215L153.528 176H176v-21.147l-19.26-30.813zM122 72c9.359 0 17.066 7.182 17.915 16.323-5.474-.916-11.046.085-15.913 2.895l-.002.001c-6.141-3.555-13.393-4.185-19.968-1.848C104.367 79.738 112.288 72 122 72zm-25.451 35.86a15.892 15.892 0 0 1 7.453-9.714c4.143-2.39 8.987-2.78 13.342-1.224a23.912 23.912 0 0 0-4.523 8.867A23.838 23.838 0 0 0 115.22 124c.63 1.092 1.358 2.104 2.134 3.068a15.875 15.875 0 0 1-9.494.385 15.894 15.894 0 0 1-9.713-7.453 15.888 15.888 0 0 1-1.597-12.14zM85.735 168H74.472l-2-4 2.51-5.019 8.289-4.144 4.319-12.956 8.774-11.698a23.858 23.858 0 0 0 9.122 4.899L85.736 168zm36.411-48.001a15.888 15.888 0 0 1-1.597-12.14 15.892 15.892 0 0 1 7.453-9.713 15.905 15.905 0 0 1 7.973-2.15c1.065 0 2.136.11 3.198.327-.981 3.417-2.853 6.226-5.72 8.593a4 4 0 0 0 5.094 6.168c3.722-3.073 6.347-6.827 7.873-11.208a15.995 15.995 0 0 1 3.433 4.125 15.888 15.888 0 0 1 1.598 12.14 15.892 15.892 0 0 1-7.453 9.713 15.9 15.9 0 0 1-12.139 1.598 15.894 15.894 0 0 1-9.713-7.453zM168 168h-9.528L156 163.056v-8.674l-8.713-8.592-5.228-10.565a24.122 24.122 0 0 0 5.94-2.443 24.232 24.232 0 0 0 3.315-2.33L168 157.146V168zM88.274 85.756a33.722 33.722 0 0 1 2.488-9.167 44.158 44.158 0 0 0-14.628-9.512C83.475 62.025 88.3 53.567 88.3 44c0-15.44-12.56-28-28-28s-28 12.56-28 28c0 9.472 4.732 17.855 11.952 22.925C27.75 73.274 16 89.286 16 108h8c0-19.85 16.15-36 36-36 11.459 0 21.677 5.388 28.274 13.756zM40.301 44c0-11.028 8.971-20 20-20s20 8.972 20 20-8.972 20-20 20-20-8.972-20-20z" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</div>