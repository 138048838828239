import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgbAlertModule, NgbCollapseModule, NgbModalModule, NgbPopoverModule, NgbToastModule, NgbTooltipModule, NgbCarouselConfig, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerComponent } from './components/spinner/spinner.component';




@NgModule({
  declarations: [SpinnerComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPermissionsModule,
    NgbAlertModule,
    NgbCollapseModule,
    NgbToastModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPermissionsModule,
    NgbAlertModule,
    NgbCollapseModule,
    NgbToastModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgbModalModule,
    NgbCarouselModule,
    SpinnerComponent,


  ]
})
export class SharedModule { }
