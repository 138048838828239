import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ScoringItem } from '../models/scoring-item.model';

@Injectable({
  providedIn: 'root'
})
export class ScoringService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getItems() {
    return this.http.get<ScoringItem[]>(this.baseUrl + 'api/scoring');
  }
}
