import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HomeRoutingModule } from './home-routing.module';
import { SharedModule } from '../shared/shared.module';
import { HomeComponent } from './home/home.component';
import { CompteClientComponent } from './compte-client/compte-client.component';
import { PrincipleComponent } from './principle/principle.component';
import { ContactComponent } from './contact/contact.component';
import { SalesContactComponent } from './sales-contact/sales-contact.component';
import { ScoringComponent } from './scoring/scoring.component';
import { ScoringCategoryComponent } from './scoring-category/scoring-category.component';
import { ScoringFamilyComponent } from './scoring-family/scoring-family.component';
import { AccountCreationComponent } from './account-creation/account-creation.component';


@NgModule({
  declarations: [HomeComponent, CompteClientComponent, PrincipleComponent, ContactComponent, SalesContactComponent,
    ScoringComponent, ScoringCategoryComponent, ScoringFamilyComponent, AccountCreationComponent],
  imports: [
    SharedModule,
    HomeRoutingModule,
    BrowserModule
  ]
})
export class HomeModule { }
