
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { CoreModule } from './core/core.module';
import { HomeModule } from './home/home.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    CoreModule,
    AppRoutingModule,
    HomeModule,
    NgbModule,
  ],
  providers: [
  ],

  bootstrap: [AppComponent]
})

export class AppModule {
  constructor() {
  }
}
