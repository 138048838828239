import { DeclarationHistoryItem } from './../../shared/models/declaration-history-item';
import { TableItemContainer } from './../../shared/models/table-item-container';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ParticipantInfo } from 'src/app/shared/models/participant-info.model';
import { Expiration } from 'src/app/shared/models/expiration.model';
import { CatalogItem } from 'src/app/shared/models/catalog-item.model';
import { Observable, timer } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { PointEvent } from 'src/app/shop/models/point-event.model';
// import { stringify } from 'querystring';

const REFRESH_INTERVAL = 30000;
const CACHE_SIZE = 1;

@Injectable({
  providedIn: 'root'
})
export class DebService {

  private cachedParticipant$: Observable<ParticipantInfo>;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  get participant() {
    // if (!this.cachedParticipant$) {
    //   const timer$ = timer(0, REFRESH_INTERVAL);

    //   // For each tick make an http request to fetch new data
    //   this.cachedParticipant$ = timer$.pipe(
    //     switchMap(_ => this.getParticipant()),
    //     shareReplay(CACHE_SIZE)
    //   );
    // }
    // return this.cachedParticipant$;
    return this.getParticipant().pipe(shareReplay(1));
  }

  getParticipant(uidOrganization?: string) {
    let queryParameters: string = uidOrganization ? '?uidOrganization=' + uidOrganization : '';
    return this.http.get<ParticipantInfo>(this.baseUrl + 'api/deb/participant' + queryParameters);
  }

  getHistory(uidOrganization?: string) {
    let queryParameters: string = uidOrganization ? '?uidOrganization=' + uidOrganization : '';
    return this.http.get<PointEvent[]>(this.baseUrl + 'api/deb/history' + queryParameters);
  }

  getExpiration(idOrganization?: number) {
    let queryParameters: string = idOrganization ? '?idOrganization=' + idOrganization : '';
    return this.http.get<Expiration>(this.baseUrl + 'api/deb/expiration' + queryParameters);
  }

  getCatalog() {
    return this.http.get<CatalogItem[]>(this.baseUrl + 'api/deb/catalog');
  }

  getDeclarations(idOrganization?: number, pageSize?: number, page?: number, search?: string) {
    let queryParameters = this.getQueryParameters(idOrganization, pageSize, page, search);
    return this.http.get<TableItemContainer<DeclarationHistoryItem>>(this.baseUrl + 'api/deb/declarations' + queryParameters);
  }

  deleteDecalaration(idDeclaration : number){
    return this.http.delete(this.baseUrl + 'api/deb/declaration?idDeclaration='+idDeclaration);
  }

  private getQueryParameters(idOrganization?: number, pageSize?: number, page?: number, search?: string): string {
    let queryParameters: string = '?';
    if (idOrganization != null && idOrganization != undefined) {
      queryParameters += 'idOrganization=' + idOrganization + '&'
    }
    if (pageSize != null && pageSize != undefined) {
      queryParameters += 'pageSize=' + pageSize + '&'
    }
    if (page != null && page != undefined) {
      queryParameters += 'page=' + page + '&'
    }
    if (search != null && search != undefined) {
      queryParameters += 'search=' + search + '&'
    }
    return queryParameters
  }
}
