import { Component, OnInit } from '@angular/core';
import { Contact } from '../../shared/models/contact.model';
import { Observable, of } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-sales-contact',
  templateUrl: './sales-contact.component.html',
  styleUrls: ['./sales-contact.component.scss']
})
export class SalesContactComponent implements OnInit {

  contact$: Observable<Contact>;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.contact$ = this.userService.getContact();
  }

}
