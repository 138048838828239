import { carouselContent } from './../../shared/models/carousel-content';
import { UrlService } from './../../core/services/url.service';
import { Component, OnInit } from '@angular/core';
import { Observable, of, forkJoin, zip, iif } from 'rxjs';
import { filter, switchMap, map, shareReplay, take } from 'rxjs/operators';
import { PermissionService } from 'src/app/core/services/permission.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
//import { OidcSecurityService } from 'src/app/shared/lib/angular-auth-oidc-client/src/lib/angular-auth-oidc-client';
import { startWith } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/user.service';
import { RegistrationStatus } from 'src/app/shared/models/registration-status.model';
import { RegistrationService } from 'src/app/core/services/registration.service';
import { Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [NgbCarouselConfig]
})
export class HomeComponent implements OnInit {
  isAuthAndValid$: Observable<boolean>;
  declarationUrl$: Observable<string>;
  isPending$: Observable<boolean>;
  isDeactivated$: Observable<boolean>;

  // authStatus$: Observable<RegistrationStatus>;

  status = RegistrationStatus;

  images = [700, 800, 807].map((n) => `https://picsum.photos/id/${n}/1100/600`);

  carouselContent: any[] = carouselContent;

  constructor(private oidcSecurityService: OidcSecurityService, private urlService: UrlService, private config: NgbCarouselConfig, private resgistrationService: RegistrationService, private router: Router) {
   // config.interval = 10000;
    config.interval = 7000;
    config.keyboard = true;
    config.pauseOnHover = true;
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
    config.wrap = true;
    config.animation = true;
  }

  ngOnInit(): void {

    this.declarationUrl$ = this.urlService.declarationUrl$.pipe(
      startWith('#'),
    );

    const authStatus$ = this.resgistrationService.getStatus();

    authStatus$.pipe(
      filter(status => status === RegistrationStatus.Invalid),
      take(1),
    ).subscribe(_ => {
      this.router.navigate(['/register']);
    });

    this.isPending$ = authStatus$.pipe(
      map(status => status === RegistrationStatus.Pending)
    );

    this.isAuthAndValid$ = authStatus$.pipe(
      map(status => status === RegistrationStatus.Valid)
    );

    this.isDeactivated$ = authStatus$.pipe(
      map(status => status === RegistrationStatus.Deactivated)
    )

  }

}
