import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ToastService } from './services/toast.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private toastService: ToastService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.error instanceof ErrorEvent) {
            // client-side error
            this.toastService.show(error.error.message, {classname: 'bg-danger text-light', headertext: 'Erreur'});

            return throwError(error);
          } else {
            // server-side error
            if (error.status === 401) {
              // console.log(error.message);
            } else if (error.status === 404) {
              // console.log(error.message);
            } else {
              this.toastService.show(error.message, {classname: 'bg-danger text-light', headertext: `Erreur ${error.status}`});
            }
            return throwError(error);
          }
        })
      );
  }
}
