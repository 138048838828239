import { CartItem } from './cart-item.model';

export class Cart {
  items: CartItem[] = [];
  total = 0;
  count = 0;

  public updateFrom(src: Cart) {
    this.items = src.items;
    this.total = src.total;
    this.count = src.count;
  }
}
