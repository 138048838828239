import { CatalogItem } from 'src/app/shared/models/catalog-item.model';

export class CartItem {
  // item: CatalogItem;
  ref: string;
  name: string;
  quantity = 0;
   total = 0;
//  valeur = 0;
 valeur=0;
}
