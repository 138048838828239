import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, finalize, first, switchMap, tap } from 'rxjs/operators';
import { OrganisationService } from 'src/app/core/services/organisation.service';
import { RegistrationService } from 'src/app/core/services/registration.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { UserService } from 'src/app/core/services/user.service';
import { NewUserInfo } from 'src/app/shared/models/new-user-info.model';
import { Organisation } from 'src/app/shared/models/organisation.model';
import { RegistrationStatus } from 'src/app/shared/models/registration-status.model';
import { UserDetailedInfo } from 'src/app/shared/models/user-detailed-info.model';

type BooleanFn = () => boolean;
function conditionalValidator(predicate: BooleanFn,
  validator: ValidatorFn): ValidatorFn {
  return (formControl => {
    if (!formControl.parent) {
      return null;
    }
    let error = null;
    if (predicate()) {
      error = validator(formControl);
    }
    return error;
  });
}

@Component({
  selector: 'app-account-creation',
  templateUrl: './account-creation.component.html',
  styleUrls: ['./account-creation.component.scss']
})
export class AccountCreationComponent implements OnInit, OnDestroy {

  status$: Observable<RegistrationStatus>;
  status = RegistrationStatus;
  siretSubscription: Subscription;
  roleSubscription: Subscription;
  activitySubscription: Subscription;
  orgaLoading = false;
  canEditOrga = false;
  userDetails: UserDetailedInfo = null;

  unexistingSiretError = false;
  existingSiretError = false;

  functions = ['Gérant', 'Comptable', 'Secrétaire', 'Technicien', 'Autre'];
  jobs = ['Plombier', 'Chauffagiste', 'Climaticien', 'Electricien', 'Frigoriste', 'Entreprise générale du bâtiment', 'Autre'];
  roles = new Map([['ChefEntreprise', `Chef d'entreprise`], ['Employe', 'Salarié']]);
  activities = ['SAV', 'Installateur'];

  formGroup: UntypedFormGroup;
  submitted = false;
  saving = false;
  sendInfo: boolean = false;

  rejected = false;

  constructor(private formBuilder: UntypedFormBuilder, private userService: UserService,
    private router: Router, private toastService: ToastService, private registrationService: RegistrationService,
    private organisationService: OrganisationService) { }

  ngOnInit(): void {
    this.status$ = this.registrationService.getStatus();

    this.buildForm();

    this.setUserDetails();

    this.listenToSiretChanges();

    this.listenToRoleChanges();

    this.listenToActivityChanges();
  }

  onSubmit() {
    this.submitted = true;
    if (this.formGroup.invalid) {
      return;
    }

    if (this.rejected) {
      this.toastService.show('Vous ne pouvez plus vous inscrire suite à trois rejets consécutifs.', { classname: 'bg-danger text-light' });
      return;
    }

    const info = this.formGroup.value; // as UserDetailedInfo;
    const infoToSave: Partial<NewUserInfo> = {
      function: info.function,
      phone: info.phone,
      siret: info.siret,
      civility: info.civility,
      description: info.companyDescription,
      email: info.email,
      firstName: info.firstName,
      lastName: info.lastName,
      role: info.role,
      sendInfo: info.sendInfo
    };

    infoToSave.company = info.company;
    infoToSave.address1 = info.address1;
    infoToSave.address2 = info.address2;
    infoToSave.locality = info.locality;
    infoToSave.zip = info.zip;
    infoToSave.city = info.city;
    infoToSave.employees = +info.employees;
    infoToSave.doSav = info.doSav;
    infoToSave.job = info.job;
    infoToSave.activity = info.activity;

    this.saving = true;
    this.userService.upsert(infoToSave)
      .pipe(
        finalize(() => this.saving = false)
      )
      .subscribe(
      res => {
        this.userService.forceReloadStatus();
        this.router.navigate(['/home']);
      },
      (err: HttpErrorResponse) => {
        this.toastService.show('Erreur lors de la création du compte : ' + err.error, { classname: 'bg-danger text-light' });
      }
    );
  }

  private listenToActivityChanges() {
    this.activitySubscription = this.activity.valueChanges.pipe(
      filter(_ => this.activity.valid),
      tap(_ => this.formGroup.patchValue({
        doSav: this.activity.value === 'SAV'
      }))
    ).subscribe();
  }

  private listenToRoleChanges() {
    this.roleSubscription = this.role.valueChanges.pipe(
      filter(role => role != null)
    )
      .subscribe(role => {
        this.siret.enable({ emitEvent: false });
        this.computeCanEditOrga();
        Object.keys(this.formGroup.controls).forEach((key) => {
          this.formGroup.get(key).updateValueAndValidity({ onlySelf: true, emitEvent: false });
        });
      });
  }

  private listenToSiretChanges() {
    this.siretSubscription = this.siret.valueChanges.pipe(
      filter(_ => this.siret.valid),
      tap(_ => {
        this.initRoles();
        this.orgaLoading = true;
      }),
      switchMap(siret => this.organisationService.getOrganisation(siret)),
      tap(orga => {
        if (orga?.hasCeo) {
          this.roles.delete('ChefEntreprise');
        }
        this.updateOrganisation(orga);
        this.checkRejections(orga);
        this.orgaLoading = false;
      }),
      finalize(() => this.orgaLoading = false)
    ).subscribe();
  }

  private setUserDetails() {
    this.userService.getDetail(false)
      .pipe(
        first(),
        tap(info => {
          this.userDetails = info;
          this.formGroup.patchValue(info);
          if (info.hasCeo) {
            this.roles.delete('ChefEntreprise');
          } else {
            this.role.setValue('ChefEntreprise');
          }
          if (info.siret) {
            // setValue is necessary to trigger the valueChanges
            this.siret.setValue(info.siret);
          }
          this.computeCanEditOrga();
        })
      ).subscribe();
  }

  private computeCanEditOrga() {
    if (this.userDetails?.siret) {
      this.canEditOrga = this.role.value === 'ChefEntreprise' && !this.userDetails.siret;
      // this.siret.disable({ emitEvent: false });
    } else {
      this.canEditOrga = this.role.value === 'ChefEntreprise';
    }
  }

  private buildForm() {
    this.formGroup = this.formBuilder.group({
      civility: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      role: [null, [Validators.required]],
      function: ['', [Validators.required]],
      job: ['', [conditionalValidator(() => this.canEditOrga, Validators.required)]],
      activity: ['', [conditionalValidator(() => this.canEditOrga, Validators.required)]],
      company: ['', [conditionalValidator(() => this.canEditOrga, Validators.required)]],
      companyDescription: [''],
      email: [''],
      phone: ['', [Validators.required]],
      address1: ['', [conditionalValidator(() => this.canEditOrga, Validators.required)]],
      address2: [''],
      locality: [''],
      zip: ['', [conditionalValidator(() => this.canEditOrga, Validators.required), Validators.pattern('^[0-9]{5}$')]],
      city: ['', [conditionalValidator(() => this.canEditOrga, Validators.required)]],
      employees: [null, [conditionalValidator(() => this.canEditOrga,
        Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')]))]],
      //siret: [{ value: '', disabled: true }, [Validators.required, Validators.pattern('^[0-9]{14}$')]],
      siret: ["", [Validators.required]],
      doSav: [null, [conditionalValidator(() => this.canEditOrga, Validators.required)]],
      acceptConfidentiality: [false, [Validators.required, Validators.requiredTrue]],
      sendInfo: [false],
    });
  }

  private checkRejections(orga: Organisation): void {
    if (orga?.rejectCount >= 3) {
      this.rejected = true;
      this.siret.setErrors({ 'invalid-siret': true });
    }
  }

  private updateOrganisation(orga: Organisation) {
    if (this.canEditOrga) {
      this.existingSiretError = orga != null && orga.hasCeo;
      this.unexistingSiretError = false;
    } else {
      this.existingSiretError = false;
      this.unexistingSiretError = orga == null;
      if (orga != null) {
        const { siret, ...patchedOrga } = orga; // remove siret
        this.formGroup.patchValue(patchedOrga);
      }
    }
    if (this.existingSiretError || this.unexistingSiretError) {
      this.siret.setErrors({ 'invalid-siret': true });
    } else {
      this.siret.setErrors(null);
    }
  }

  private initRoles() {
    this.roles = new Map([['ChefEntreprise', `Chef d'entreprise`], ['Employe', 'Salarié']]);
  }

  get function() {
    return this.formGroup.get('function');
  }
  get job() {
    return this.formGroup.get('job');
  }
  get firstName() {
    return this.formGroup.get('firstName');
  }
  get lastName() {
    return this.formGroup.get('lastName');
  }
  get phone() {
    return this.formGroup.get('phone');
  }
  get email() {
    return this.formGroup.get('email');
  }
  get company() {
    return this.formGroup.get('company');
  }
  get address1() {
    return this.formGroup.get('address1');
  }
  get address2() {
    return this.formGroup.get('address2');
  }
  get locality() {
    return this.formGroup.get('locality');
  }
  get city() {
    return this.formGroup.get('city');
  }
  get siret() {
    return this.formGroup.get('siret');
  }
  get employees() {
    return this.formGroup.get('employees');
  }
  get zip() {
    return this.formGroup.get('zip');
  }
  get activity() {
    return this.formGroup.get('activity');
  }
  get role() {
    return this.formGroup.get('role');
  }
  get description() {
    return this.formGroup.get('companyDescription');
  }
  get doSav() {
    return this.formGroup.get('doSav');
  }
  get acceptConfidentiality() {
    return this.formGroup.get('acceptConfidentiality');
  }
  get civility() {
    return this.formGroup.get('civility');
  }

  ngOnDestroy(): void {
    if (this.siretSubscription != null) {
      this.siretSubscription.unsubscribe();
    }
    if (this.roleSubscription != null) {
      this.roleSubscription.unsubscribe();
    }
    if (this.activitySubscription != null) {
      this.activitySubscription.unsubscribe();
    }
  }
}
