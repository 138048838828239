
import { EnsureModuleLoadedOnceGuard } from './ensure-module-loaded-once.guard';
import { Optional, SkipSelf, LOCALE_ID, NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { ToastsContainer } from './components/toasts-container/toasts-container.component';
import { LoginMenuComponent } from './components/login-menu/login-menu.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { AuthorizeInterceptor } from './authorize.interceptor';
import { AuthModule, OidcConfigService, StsConfigHttpLoader, StsConfigLoader } from 'angular-auth-oidc-client';
//import { AuthModule, OidcConfigService, StsConfigHttpLoader, StsConfigLoader } from 'src/app/shared/lib/angular-auth-oidc-client/src/lib/angular-auth-oidc-client';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ConfigService } from './services/config.service';
import { LoginComponent } from './components/login/login.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { SecretInterceptor } from './secret.interceptor';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ImprintComponent } from './components/imprint/imprint.component';
import { LegalNoticesComponent } from './components/legal-notices/legal-notices.component';
import { SalesTermsComponent } from './components/sales-terms/sales-terms.component';
import { LogoutComponent } from './components/logout/logout.component';

// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');

// export function init_auth(configService: ConfigService, oidcConfigService: OidcConfigService) {
//   return () => configService.load()
//     .then((config) => oidcConfigService.withConfig(config));
// }

export function init_auth(configService: ConfigService) {
  return () => configService.load()
}

export const httpLoaderFactory = (configService: ConfigService, httpClient:HttpClient) => {
  // const config$ = configService.load();
  const config$ = configService.load();

  return new StsConfigHttpLoader(config$);

};

@NgModule({
  declarations: [
    // ...fromComponents.components
    HeaderMenuComponent, NavMenuComponent, FooterComponent, ToastsContainer, LoginMenuComponent,
    UnauthorizedComponent, LoginComponent, PrivacyPolicyComponent, ImprintComponent, LegalNoticesComponent, SalesTermsComponent, LogoutComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: HTTP_INTERCEPTORS, useClass: SecretInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    ConfigService,
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_auth,
      deps: [ConfigService, OidcConfigService],
      multi: true,
    },
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule,
    SharedModule,
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: httpLoaderFactory,
        deps: [ConfigService],
      },
    }),
    NgxPermissionsModule.forRoot(),
  ],
  exports: [
    HeaderMenuComponent, NavMenuComponent, FooterComponent, LoginMenuComponent, ToastsContainer
    // ...fromComponents.components
  ]
})

export class CoreModule extends EnsureModuleLoadedOnceGuard {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
