<main class="main">

  <ngb-alert [dismissible]="false" type="warning" *ngIf="isPending$ | async">
    Votre compte est en attente de validation.
  </ngb-alert>
  <ngb-alert [dismissible]="false" type="warning" *ngIf="isDeactivated$ | async">
    Votre compte a été désactivé, vous n'avez plus accès au Club Degré +.
  </ngb-alert>
  <div class="container-fluid m-0 p-0">

    <!-- <ngb-carousel *ngIf="images">
      <ng-template ngbSlide>
        <div class="wrapper">
          <img [src]="images[0]" alt="Random first slide">
        </div>
        <div class="text-center d-md-none mt-4">
          <h2>Votre Fidélité récompensée</h2>
          <h2>à sa juste valeur</h2>
          <p>Le Club Degré+ c'est l'outil de deux marques Bosch et elm.leblanc qui s'allient</p>
          <p>pour récompenser votre fidélité et vous offrir le plein d'avantages !</p><br>
          <p>Plus vous déclarez de produits, plus vous bénéficiez d'avantages...</p>
        </div>
        <div class="carousel-caption d-none d-md-block">
          <h2>Votre Fidélité récompensée</h2>
          <h2>à sa juste valeur</h2>
          <p>Le Club Degré+ c'est l'outil de deux marques Bosch et elm.leblanc qui s'allient</p>
          <p>pour récompenser votre fidélité et vous offrir le plein d'avantages !</p><br>
          <p>Plus vous déclarez de produits, plus vous bénéficiez d'avantages...</p>
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="wrapper">
          <img [src]="images[1]" alt="Random second slide">
        </div>
        <div class="text-center d-md-none mt-4">
          <h2>Votre Fidélité récompensée</h2>
          <h2>à sa juste valeur</h2>
          <p>Le Club Degré+ c'est l'outil de deux marques Bosch et elm.leblanc qui s'allient</p>
          <p>pour récompenser votre fidélité et vous offrir le plein d'avantages !</p><br>
          <p>Plus vous déclarez de produits, plus vous bénéficiez d'avantages...</p>
        </div>
        <div class="carousel-caption d-none d-md-block">
          <h2>Votre Fidélité récompensée</h2>
          <h2>à sa juste valeur</h2>
          <p>Le Club Degré+ c'est l'outil de deux marques Bosch et elm.leblanc qui s'allient</p>
          <p>pour récompenser votre fidélité et vous offrir le plein d'avantages !</p><br>
          <p>Plus vous déclarez de produits, plus vous bénéficiez d'avantages...</p>
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="wrapper">
          <img [src]="images[2]" alt="Random third slide">
        </div>
        <div class="text-center d-md-none mt-4">
          <h2>Votre Fidélité récompensée</h2>
          <h2>à sa juste valeur</h2>
          <p>Le Club Degré+ c'est l'outil de deux marques Bosch et elm.leblanc qui s'allient</p>
          <p>pour récompenser votre fidélité et vous offrir le plein d'avantages !</p><br>
          <p>Plus vous déclarez de produits, plus vous bénéficiez d'avantages...</p>
        </div>
        <div class="carousel-caption d-none d-md-block">
          <h2>Votre Fidélité récompensée</h2>
          <h2>à sa juste valeur</h2>
          <p>Le Club Degré+ c'est l'outil de <br>deux marques Bosch et elm.leblanc qui s'allient</p>
          <p>pour récompenser votre fidélité et vous offrir le plein d'avantages !</p><br>
          <p>Plus vous déclarez de produits, plus vous bénéficiez d'avantages...</p>
        </div>
      </ng-template>
    </ngb-carousel> -->

    <ngb-carousel *ngIf="carouselContent.length > 0">
      <ng-template ngbSlide *ngFor="let item of carouselContent">
        <div class="wrapper " >
          <img [src]="item.imageUrl" alt="Random first slide">
        </div>
        <div class="text-center d-sm-none mt-4" >
          <!-- <h2>{{item.title}}</h2> -->
          <h1 *ngFor="let title of item.titles">{{title}}</h1>

          <p *ngFor="let text of item.texts">{{text}}</p>
        </div>
        <div class="carousel-caption d-none d-md-block">
          <h1 *ngFor="let title of item.titles">{{title}}</h1>
          <p *ngFor="let text of item.texts">{{text}}</p>

          <div *ngIf="item.button != null" >
            <button    type="button"   class="button-banniere btn btn-light"><a href={{item.url}} alt='Broken Link' >{{item.button}}</a></button>
          </div>


        </div>
        <div *ngIf="item.button != null" >
          <button   type="button"   class="button-banniere-mobile hide-on-desktop btn btn-dark" aria-labelledby="hide-on-desktop"><a href={{item.url}} alt='Broken Link' >{{item.button}}</a></button>
        </div>

      </ng-template>
    </ngb-carousel>

  </div>

  <div class="container-fluid line-middle" *ngIf="isAuthAndValid$ | async">

    <div class="d-flex flex-column flex-lg-row py-5 justify-content-around">
      <div class="compteElement p-4 col-12 col-md-4 col-lg-3">
        <app-compte-client></app-compte-client>
      </div>
      <div class="d-flex flex-column px-4 pt-5 pb-4 col-12 col-md-4 col-lg-3 declarationElement">
        <div class="d-flex mt-auto mb-4 declarationLogo">
          <svg class="mx-auto" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            width="138.449" height="98.45" viewBox="0 0 138.449 98.45">
            <path class="a"
              d="M16,133.118H50.408V140H16ZM149.159,46.84a18.067,18.067,0,0,0-25.548,0L64.429,106.022,56,140l33.978-8.516L146.75,74.711l2.409-2.409a17.964,17.964,0,0,0,0-25.462Zm-76.644,60.9,46.709-46.795,15.742,15.828L88.257,123.484,72.515,107.742Zm-3.011,6.71,12.043,12.043-16.086,4.043L69.5,114.452Zm75.354-47.569-4.989,4.989L124.127,56.044l4.9-4.9a11.192,11.192,0,0,1,15.828,15.742Z"
              transform="translate(-16 -41.55)" />
          </svg>
        </div>
        <div class="declarationText mt-3"><a [href]="(declarationUrl$ | async)">Faire une déclaration</a></div>
      </div>
      <div class="d-flex flex-column px-4 py-5 col-12 col-md-4 col-lg-3 boutiqueElement" *ngxPermissionsOnly="['ChefEntreprise']">
        <div class="d-flex mt-auto mb-4 boutiqueLogo">
          <svg class="mx-auto" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            width="98.45" height="98.45" viewBox="0 0 98.45 98.45">
            <path class="a"
              d="M130.45,56.612H115.067A15.383,15.383,0,0,0,102.761,32c-6.692,0-13,3.153-17.767,8.768a29.18,29.18,0,0,0-3.769,5.845,29.179,29.179,0,0,0-3.769-5.845C72.688,35.153,66.381,32,59.689,32A15.383,15.383,0,0,0,47.383,56.612H32V81.225h3.077V130.45h92.3V81.225h3.077ZM124.3,75.072H93.531V62.766H124.3ZM102.761,38.153a9.23,9.23,0,0,1,0,18.459H84.532C85.84,47.383,92.993,38.153,102.761,38.153Zm-52.3,9.23a9.257,9.257,0,0,1,9.23-9.23c9.768,0,16.921,9.23,18.229,18.459H59.689A9.257,9.257,0,0,1,50.459,47.383ZM38.153,62.766H68.919V75.072H38.153ZM68.919,124.3H41.23V81.225H68.919Zm18.459,0H75.072V62.766H87.378Zm33.842,0H93.531V81.225H121.22Z"
              transform="translate(-32 -32)" />
          </svg>
        </div>
        <div class="boutiqueText mt-3"><a [routerLink]="['/shop']">Boutique</a></div>
      </div>
    </div>
  </div>
</main>
