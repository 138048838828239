import { shareReplay } from 'rxjs/operators';
import { UserService } from './../../core/services/user.service';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public clubStatus$: Observable<string>;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.clubStatus$ = this.userService.getClubStatus().pipe(shareReplay(1));
  }

}
