import { CartItem } from './../../shared/models/cart-item.model';
import { Injectable } from '@angular/core';

import { ReplaySubject, BehaviorSubject } from 'rxjs';
import { CatalogItem } from 'src/app/shared/models/catalog-item.model';
import { Cart } from '../../shared/models/cart.model';
import { StorageService } from 'src/app/core/services/storage.service';
import { isTemplateExpression, isTemplateSpan } from 'typescript';

const CART_KEY = 'cart';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private storage: Storage;
  placeholder = [];

   private _cart = new ReplaySubject<Cart>(1);

  readonly cart$ = this._cart.asObservable();



  constructor(private storageService: StorageService) {
    this.storage = this.storageService.get();

    this._cart.next(this.retrieve());

    const ls = JSON.parse(localStorage.getItem('cart'))
    if(ls) this._cart.next(ls)
  }

  addToCart(item: CatalogItem, quantity: number) {

    const cart = this.retrieve();


    let cartItem = cart.items.find((p) => p.ref === item.ref);
    if (cartItem === undefined) {
      cartItem = new CartItem();
      cartItem.ref = item.ref;
      cartItem.name = item.nom;
      // cartItem.valeur = item.valeur;


      cart.items.push(cartItem);
    }

    cartItem.quantity += quantity;
    cartItem.total = cartItem.quantity * item.valeur;
    // cartItem.valeur = cartItem.quantity * item.valeur;
    this.calculateCart(cart);
    this.save(cart);
    this._cart.next(cart);
  }

  clearCart() {
    const cart = new Cart();
    this.save(cart);
    this._cart.next(cart);
  }

  editQuantity(item: CatalogItem, quantity: number) {
    console.log("Debut du aditQantity")
    const cart = this.retrieve();

    const cartItem = cart.items.find((p) => p.ref === item.ref);
    if (cartItem === undefined) {
      return;
    }

      cartItem.quantity = quantity;
      cartItem.total = cartItem.quantity * item.valeur;
      cartItem.valeur = item.valeur;
      console.log("Cart total", cartItem.total);

        //  this._cart.next(cart);

        this.calculateCart(cart);

      console.log("Avant le save Service");
      this.save(cart);
        this._cart.next(cart);
       console.log("Aprés le next")

  }

  updateCartItem(item: CatalogItem) {

    console.log("Debut de Upadete CART");
    const cart = this.retrieve();

    const cartItem = cart.items.find((p) => p.ref === item.ref);
    if (cartItem === undefined) {
      return;
    }

     if(cartItem.valeur != item.valeur){

      cartItem.total = cartItem.quantity * item.valeur;
      cartItem.valeur = item.valeur;
      this.calculateCart(cart);
      this.save(cart);
      this._cart.next(cart)
      console.log("Aprés le next")
     }

     else{

      return;
     }

  }


  removeFromCart(ref: string) {
    const cart = this.retrieve();

    cart.items = cart.items.filter((cartItem) => cartItem.ref !== ref);

    this.calculateCart(cart);
    this.save(cart);
    this._cart.next(cart);

  }

  private calculateCart(cart: Cart): void {
    cart.count = cart.items.reduce((sum, item) => sum += item.quantity, 0);
     cart.total = cart.items.reduce((sum, item) => sum += item.total, 0);
    // cart.total = cart.items.reduce((sum, item) => sum += item.valeur, 0)


  }

  private retrieve(): Cart {
    const cart = new Cart();
    const storedCart = this.storage.getItem(CART_KEY);
    if (storedCart) {
      cart.updateFrom(JSON.parse(storedCart));
    }

    return cart;
  }


    private save(cart: Cart): void {

     this.storage.setItem(CART_KEY, JSON.stringify(cart));

  }

}
