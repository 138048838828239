import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, Subscription, Subject } from 'rxjs';
import { shareReplay, takeUntil, tap } from 'rxjs/operators';
import { UserInfo } from 'src/app/shared/models/user-info.model';
import { NgxPermissionsService } from 'ngx-permissions';
import { UserDetailedInfo, UserEditableInfo } from 'src/app/shared/models/user-detailed-info.model';
import { Contact } from 'src/app/shared/models/contact.model';
import { NewUserInfo } from 'src/app/shared/models/new-user-info.model';
import { RegistrationStatus } from 'src/app/shared/models/registration-status.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private cachedStatus$: Observable<RegistrationStatus>;
  private reload$ = new Subject();

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string,
    private ngxPermissionsService: NgxPermissionsService) { }

  getPermissions(): Observable<string[]> {
    return this.http.get<string[]>(this.baseUrl + 'api/user/permissions')
      .pipe(
        //  shareReplay(1),
      );
  }

  get status$() {
    if (!this.cachedStatus$) {
      this.cachedStatus$ = this.getStatus().pipe(
        takeUntil(this.reload$),
        shareReplay(1)
      );
    }

    return this.cachedStatus$;
  }

  private getStatus() {
    return this.http.get<RegistrationStatus>(this.baseUrl + 'api/user/status');
  }

  forceReloadStatus() {
    // Calling next will complete the current cache instance
    this.reload$.next();

    // Setting the cache to null will create a new cache the
    // next time 'jokes' is called
    this.cachedStatus$ = null;
  }

  getUserInfos(): Observable<UserInfo> {
    return this.http.get<UserInfo>(this.baseUrl + 'api/user/infos');
  }

  getType(): Observable<string> {
    return this.http.get(this.baseUrl + 'api/user/type', { responseType: 'text' });
  }

  getClubStatus(): Observable<string> {
    return this.http.get(this.baseUrl + 'api/user/clubStatus', { responseType: 'text' });
  }

  getDetail(declarationCount: boolean = true, idUser?: number): Observable<UserDetailedInfo> {
    let url = this.baseUrl + 'api/user/detail?declarationCount=' + declarationCount;
    if (idUser != null) {
      url = url + '&idUser=' + idUser;
    }

    return this.http.get<UserDetailedInfo>(url);
  }

  saveDetail(info: UserEditableInfo) {
    return this.http.post(this.baseUrl + 'api/user/detail', info);
  }

  getContact(): Observable<Contact> {
    return this.http.get<Contact>(this.baseUrl + 'api/user/contact');
  }

  upsert(info: Partial<NewUserInfo>) {
    console.log("Siret : " + info.siret);
    return this.http.post(this.baseUrl + 'api/user', info);
  }
}
