import { async } from '@angular/core/testing';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
//import { OidcSecurityService } from 'src/app/shared/lib/angular-auth-oidc-client/src/lib/angular-auth-oidc-client';



@Injectable({
  providedIn: 'root'
})
export class AuthorizeInterceptor implements HttpInterceptor {
  constructor(private oidcSecurityService: OidcSecurityService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
    const token = this.oidcSecurityService.getAccessToken('openIdConfig1');

    if (!!token && this.isSameOriginUrl(req)) {
      const payload = this.oidcSecurityService.getPayloadFromIdToken();

      let headers = req.headers.append('Authorization', `Bearer ${token}`);
      if (!!payload.email) {
        headers = headers.append('Email', payload.email);
      }





      req = req.clone({
        headers: headers
      });

    }



    return next.handle(req);


  }


  private isSameOriginUrl(req: any) {
    // It's an absolute url with the same origin.
    if (req.url.startsWith(`${window.location.origin}/`)) {
      return true;
    }

    // It's a protocol relative url with the same origin.
    // For example: //www.example.com/api/Products
    if (req.url.startsWith(`//${window.location.host}/`)) {
      return true;
    }

    // It's a relative url like /api/Products
    if (/^\/[^\/].*/.test(req.url)) {
      return true;
    }



    // It's an absolute or protocol relative url that
    // doesn't have the same origin.
    return false;
  }

  public getTokenExpirationDate(dataToken:any):Date  {

    if(!dataToken.hasOwnProperty("exp")){
      return new Date(new Date().toUTCString())
    }

    const date = new Date(0)
    date.setUTCSeconds(dataToken.exp);
    console.log(date)
    return date

  }
}
