import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { UrlService } from '../../services/url.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {

  cubeUrl: Observable<string>;

  constructor(private urlService: UrlService, public router : Router) { }

  ngOnInit(): void {
    this.cubeUrl = this.urlService.getCubeUrl().pipe(
      startWith('#'),
    );
  }
}
