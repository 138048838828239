export class ScoringFamily {
  marque: ScoringBrand;
  imageUrl: string;
  nom: string;
}

export enum ScoringBrand {
  Elm = 'elm.leblanc',
  Bosch = 'Bosch'
}
