
import { OperationConcoursComponent } from './core/components/operation-concours/operation-concours.component';
import { SalesTermsComponent } from './core/components/sales-terms/sales-terms.component';
import { LegalNoticesComponent } from './core/components/legal-notices/legal-notices.component';
import { ImprintComponent } from './core/components/imprint/imprint.component';
import { PrivacyPolicyComponent } from './core/components/privacy-policy/privacy-policy.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { authRoutes } from './core/auth.routes';
import { LogoutComponent } from './core/components/logout/logout.component';

const appRoutes: Routes = [
  { path: 'home', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'administration',
    loadChildren: () => import('./back-office/back-office.module').then(m => m.BackOfficeModule),
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
  },
  {
    path: 'shop',
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule),
  },
  {
    path: 'promotions',
    loadChildren: () => import('./promotions/promotions.module').then(m => m.PromotionsModule)
  },
  {
    path: 'PolitiqueConfidentialite',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'Imprint',
    component: ImprintComponent,
  },
  {
    path: 'MentionsLegales',
    component: LegalNoticesComponent,
  },
  {
    path: 'ConditionsGeneralesVente',
    component: SalesTermsComponent,
  },

  {
    path: 'GrandJeuConcoursClimatisation2022',
    component : OperationConcoursComponent
  },

  {
    path: 'authentication/logout-callback',
    component: LogoutComponent
  },

  { path: '', redirectTo: 'home', pathMatch: 'full' },
  //{ path: '**', redirectTo: 'home' }, // If no matching route found, go back to home route

];


@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes.concat(authRoutes),
      {
        scrollPositionRestoration: 'enabled',
        relativeLinkResolution: 'legacy',
        onSameUrlNavigation: 'reload',
        preloadingStrategy: PreloadAllModules
      }
    ),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
