<main class="home">
  <section style="font-family: 'BoschSansRegular',sans-serif;font-size: 14px;line-height: 1;color: #005691;margin:5px;">
    <div class="container">
      <div class="title" style="font-size:30px;">Règlement du Grand Jeu Concours Climatisation 2022</div>

      <div class="content row" style="margin-top:30px;">
        <section id="" class="section fs-two-column-text color--black theme--light bgcolor--white">
          <div class="container">
            <div class="row">
              <div class="col-sm-6">
                <p><b>Article 1. Définitions </b></p>
                <p><b>« Adhérent(s) » </b></p>
                <p>Désigne le professionnel ou la personne morale inscrit au Club Degré plus et ayant accepté ses conditions d’adhésion (gérant de l’entité légale). </p>

                <p><b>« Club Degré Plus »</b></p>
                <p>Désigne le programme de fidélité destiné aux clients professionnels (artisans, entrepreneurs individuels,
                   sociétés commerciales identifiés par un n°SIRET installateurs et SAV indépendants de système de chauffage et de productions d’eau chaude sanitaire
                   Bosch et elm.leblanc en France Métropolitaine. </p>
                <p><b>« Durée de l’Opération »</b></p>
                <p>Désigne la période entre le 1er avril 2022 à 00h00 et le 31 août 2022 à 24h00.</p>
                <p><b>« Espace »</b></p>
                <p>Désigne l’espace Entreprise créé par l’Adhérent lors de son inscription au Club Degré plus sur le Site dans lequel l’Adhérent déclare ses installations </p>
                <p><b>« Gagnant(s) »</b></p>
                <p>Désigne les gagnants des lots tirés au sort en présence de
                  l’Huissier parmi les Participants dans les conditions décrites au présent Règlement.
                </p>
                <p><b>« Huissier »</b></p>
                <p>Désigne l’étude d’huissier :   <br/><br/>
                   SCP PARHUIS <br/><br/>
                   51, rue Sainte-Anne <br/><br/>
                  75002 PARIS.
                </p>
                <p><b>« Lots »</b></p>
                <p>Désigne les lots mis en jeu dans le cadre de l’Opération en vue d’être attribués aux Gagnants et décrits à l’article 5 du Règlement.
                </p>
                <p><b>« Opération »</b></p>
                <p>Désigne le Grand Jeu Concours Climatisation 2022 dont l’objet est de permettre aux Participants de gagner les Lots.</p>
                <p><b>« Participant(s) »</b></p>
                <p>Désigne les Adhérents remplissant les conditions de participation au tirage au sort prévues à l’article 4 du Règlement.  </p>
                <p><b>« Pools »</b></p>
                <p>Désigne les groupes de Participants répartis en Région de vente en fonction de la localisation géographique de leur siège social.  </p>
                <p><b>« Région de vente »</b></p>
                <p>Désigne les 6 régions commerciales Bosch elm.leblanc, à savoir <br/><br/>
                   Région  ILE DE FRANCE, <br/><br/> Région NORD-EST,<br/><br/> Région AURA,<br/><br/>
                   Région SUD-EST PACA,<br/><br/> Région SUD-OUEST,<br/><br/> Région GRAND OUEST.</p>
                <p><b>« Règlement »</b></p>
                <p>Désigne le présent règlement précisant les conditions et modalités applicables à l’Opération. </p>
                <p><b>« Site »</b></p>
                <p>Désigne le site internet suivant : <br /> <br />
                 <a href="https://www.degreplus.elmlecube.fr/principle " target="_blank">https://www.degreplus.elmlecube.fr/principle </a> </p>
                <p><b>« Société organisatrice » </b></p>
                <p>Désigne la société elm.leblanc S.A.S. ayant son siège social au <br/><br/>  124-126 rue de Stalingrad 93711 Drancy cedex,<br/><br/>
                   inscrite au registre du Commerce et des Sociétés de Bobigny sous le numéro <br/> <br/>  542 097 944.</p>
                <p><b>Article 2. Société organisatrice </b></p>
                <p>La Société organisatrice de l’Opération est la société elm.leblanc S.A.S. ayant son siège social au <br/> <br/>
                  124-126 rue de Stalingrad 93711 Drancy cedex,<br/> <br/>
                  inscrite au registre du Commerce et des Sociétés de Bobigny sous le numéro <br/> <br/>542 097 944.  <br/> <br/>
                  Elle organise l’Opération sur le Site en partenariat avec sa société-mère,<br/> <br/> la société Robert Bosch France ayant son siège social au<br/> <br/>
                   32 avenue Michelet 93400 Saint-Ouen <br/> <br/>
                   inscrite au registre du commerce et des sociétés de Bobigny sous le numéro <br/> <br/>572 067 684.
                </p>
                <p></p>
                <p><b>Article 3. Description et durée de l’Opération</b></p>
                <p>L’Opération intitulée « Grand Jeu Concours Climatisation 2022 » qui dure le 1er avril 2022 à 00h00 et le 31 août 2022 à 24h00 est un jeu concours permettant à six (6) Gagnants,
                   un par Région de vente, d’être tirés au sort parmi les Participants en présence de l’Huissier et de remporter un Lot chacun.  </p>
                <p><b>Article 4. Critères d’éligibilité à l’Opération </b></p>
                <p>Pour Participer, le Participant doit être un Adhérent répondant aux critères prévus ci-après : <br /> <br />
                  -	Le siège social de l’Adhérent doit se situer en France métropolitaine, <br />
                  -	L’Adhérent doit être inscrit au registre du commerce avec le code APE 4322B,<br />
                  -	L’Adhérent doit être membre du club Degré plus.<br /><br />
                  Si l’Adhérent ne répond pas à l’ensemble des critères prévus ci-dessus, il n’est pas éligible pour participer à l’Opération. <br /><br />
                  Par ailleurs, une seule participation par Adhérent est autorisée. Si la Société organisatrice identifie plusieurs participations
                  à l’Opération pour un même Adhérent, l’ensemble des participations de l’Adhérent concerné seront considérées nulles dans le cadre de l’Opération.
                </p>
                <p><b>Article 5. Description des Lots </b></p>
                <p>Un lot est constitué d’une voiture électrique AMI de marque française CITROËN d’une valeur à partir
                  de 7390 € TTC.
                  Il s’agit d’une voiture 100 % électrique sans permis qui, selon les indications disponibles sur le
                  site du constructeur <br /><br /> <a href="https://www.citroen.fr/ami" target="_blank">https://www.citroen.fr/ami</a>
                    : <br /><br />
                  - possède 75 km d’autonomie, <br /><br />
                  - nécessite 3 heures pour 100% de charges, 220 volts, <br /><br />
                  - n’émet pas de CO2, <br /><br />
                  - ne fait pas de bruit. <br /> <br />
                  Six (6) lots sont mis en jeu dans le cadre de l’Opération et sont attribués à raison de un (1) lot par Région de vente.  <br /> <br />
                  Les Lots sont destinés exclusivement aux Gagnants désignés conformément au présent Règlement pour une utilisation dans le cadre de leur activité professionnelle. Ils ne peuvent en aucun cas faire l’objet d’une cession, location, crédit-bail ou plus généralement
                  d’une quelconque mise à disposition à titre gratuit ou onéreux ni à des tiers ni, le cas échéant, à des salariés de l’Adhérent.
                </p>
                <p><b>Article 6. Déroulement de l’Opération </b></p>
                <p>Pour participer au tirage au sort, l’Adhérent doit valider sa participation sur la plateforme du club Degré+ et déclarer un minimum de deux (2) installations de climatisations de la gamme résidentielle mono-split ou multi-split conformément aux règles du club Degré plus (déclarer le numéro de série de l’unité extérieure)
                   en France Métropolitaine sur son Espace Entreprise au sein du Club Degré plus pendant la Durée de l’Opération. <br /><br />
                   Toute déclaration effectuée en dehors de la Durée de l’Opération ne sera pas prise en compte dans le cadre du décompte ci-avant mentionné.  <br /><br />
                   Pour éviter toute ambiguïté, il est précisé que la déclaration de deux (2) installations de climatisations au sein de l’Espace est suffisante pour participer
                  à l’Opération et qu’un nombre de déclaration supérieur n’augmente ni n’amoindrit les chances de gain des Participants.<br /><br />
                  En outre, toute déclaration fausse, erronée ou effectuée en violation du présent Règlement, de même que toute tentative de fraude de quelque nature qu’elle soit
                  au présent Règlement entraînera la nullité de la participation de l’Adhérent à l’Opération.
                </p>

              </div>
              <div class="col-sm-6">
                <p><b>Article 7. Tirage au sort</b></p>
                <p>A l’issue de la Durée de l’Opération, le tirage au sort sera effectué en présence de l’Huissier afin de désigner les Gagnants
                   dans les conditions suivantes le 15/09/2022.<br /><br />
                   Préalablement au tirage au sort, les Participants seront répartis en Pool à raison de un (1) Pool par Région de vente, soit un total
                   de six (6) Pools. Le tirage au sort sera ensuite effectué afin de désigner un Gagnant par Pool.  <br /> <br />
                   Trois (3) Participant(s) supplémentaire(s) par Pool seront tirés au sort et mis chronologiquement sur liste d’attente (ci-après « Gagnants subséquents »).
                   Ils seront pris en considération en cas de refus ou d’absence de réponse du Gagnant initial et le cas échéant des Gagnants subséquents de rang supérieur du Pool concerné.
                </p>
                <p></p>
                <p><b>Article 8. Modalités d’information des Gagnants </b></p>
                <p>Les Gagnants tirés au sort en seront informés par courriel à l’adresse renseignée sur leur Espace. Une fois ce courriel reçu, les Gagnants sont invités à en accuser réception en contactant la Société organisatrice dans un délai de huit (8)
                   jours à compter de sa réception aux coordonnées indiquées dans le courriel afin de réclamer leur Lot et convenir des modalités de remise.  <br /> <br />
                   A défaut de réponse du Gagnant dans le délai ci-avant mentionné, le Lot sera réputé refusé et sera réattribué au Gagnant subséquent figurant sur la liste d’attente telle que prévue
                   à l’article 7 du Règlement sans que le Gagnant de rang supérieur ne puisse contester cette réattribution.  <br /> <br />
                   Si aucun Gagnant, en ce compris les Gagnants subséquents, ne se manifeste dans les délais précisés ci-avant, les Lots ne seront pas attribués.
                </p>
                <p><b>Article 9. Remise du lot </b></p>
                <p>
                  La remise des lots se fera dans chaque Région de vente dans un lieu qui sera convenu avec les différentes parties,
                  en présence du Gagnant, du distributeur auprès duquel il s’est approvisionné, des équipes Bosch locales et du siège.  <br /><br />
                  Le gagnant accepte que elm.leblanc communique sur l’événement en interne comme en externe et donne le droit
                  d’utilisation de son image dans les médias (site internet, réseaux sociaux, presse).  <br /><br />
                   Il appartiendra au Gagnant de vérifier le bon état du Lot lors de sa réception.  <br />
                   En cas de dommage constaté, le Gagnant pourra apposer ses réserves sur le bon de réception du Lot qu’il adressera au transporteur et à la Société organisatrice dans un délai de
                   trois (3) jours ouvrables par lettre recommandée avec accusé de réception.
                </p>
                <p><b>Article 10. Frais de participation </b></p>
                <p>Il n’y a aucun frais de participation, l’Opération est gratuite. </p>
                <p><b>Article 11. Disponibilités du Règlement </b></p>
                <p>Le Règlement est disponible et consultable sur le Site à l’adresse suivante : <br /><br />
                 <a href="https://www.degreplus.elmlecube.fr/GrandJeuConcoursClimatisation2022" target="_blank">https://www.degreplus.elmlecube.fr/GrandJeuConcoursClimatisation2022</a> <br /><br />
                  Il a été par ailleurs déposé chez l’Huissier. <br /><br />
                  SCP PARHUIS <br /><br />
                  51, rue Sainte-Anne <br /><br />
                  75002 PARIS<br /><br />
                  Téléphone : 01 42 36 54 24
                </p>
                <p><b>Article 12. Acceptation du Règlement </b></p>
                <p>En participant à l’Opération, le Participant accepte le présent Règlement sans réserve </p>
                <p><b>Article 13. Annulation ou modification du Règlement </b></p>
                <p>En cas de survenance d’évènements extérieurs à la Société organisatrice, cette dernière se réserve le droit de modifier, compléter, proroger, suspendre, annuler ou reporter tout ou partie de l'Opération sans préavis
                   sans qu'aucun dédommagement ne puisse être réclamé, ou que sa responsabilité ne puisse être engagée de ce fait. <br/><br/>
                   Le cas échéant, les modifications apportées au Règlement seront publiées sur le Site et une version modifiée du Règlement adressée à l’Huissier.
                </p>
                <p><b>Article 14. Traitement des données à caractère personnel</b> </p>
                <p>La Société organisatrice, en sa qualité de responsable du traitement, respecte la vie privée et s’engage à la protéger. <br /><br />
                  La présente déclaration reflète la culture de la Société organisatrice et manifeste sa volonté de promouvoir une collecte
                  et une utilisation de vos données loyales, responsables et sécurisées.<br /><br />
                  La Société organisatrice est amenée à collecter les données à caractère personnel des Adhérents dans le cadre
                  de l’Opération aux fins de la bonne exécution de l’Opération.<br /><br />
                  Pour le bon déroulement de cette Opération et de la participation du Participant, la Société organisatrice est susceptible de communiquer les données personnelles des Adhérents
                  à d’autres entités du Groupe Bosch, et d’autres responsables de traitement tel l’huissier, le concessionnaire.<br /><br />
                  Les données personnelles des Adhérents sont enregistrées et sauvegardées pour une durée qui n’excède pas la durée nécessaire à la finalité pour lesquelles elles sont collectées et traitées, à savoir,
                  le temps de l’Opération (incluant la gestion des réclamations) soit jusqu’au 31 décembre 2022.  <br /><br />
                  Conformément à la réglementation en vigueur applicable aux traitements des données à caractère personnel et, en particulier, le règlement (UE) 2016/679 applicable à compter du 25 mai 2018 (le règlement européen relatif à la protection des données dit RGPD),
                  les Adhérents disposent d’un droit d’accès, de rectification ou d’effacement, de mise à jour, de limitation de leurs données personnelles <br /><br />
                  Les Adhérents disposent également du droit à la portabilité. Le droit à la portabilité se distingue du droit d’accès qui s’exerce sur l’ensemble des données personnelles. <br/><br/>
                  Le droit à la portabilité ne s’exerce que sur les données que les Adhérents fournissent à la Société organisatrice et traitées sur la base de leur consentement,
                  de l’exécution d’un contrat ou à l’aide de procédés automatisés.<br /><br />
                  Les Adhérents disposent du droit de donner des directives sur le sort de leurs données personnelles après leur décès.<br /><br />
                  Les Adhérents disposent du droit d’introduire une réclamation auprès de l’autorité de contrôle, la CNIL (Commission Nationale Informatique et Libertés), à l’adresse postale suivante : <br/><br/>
                  3, place de Fontenoy <br/> <br/>
                  75007 Paris <br/><br/>
                  <a href="https://www.cnil.fr/fr/agir" target="_blank">https://www.cnil.fr/fr/agir</a> <br/><br/>
                  Pour faire valoir vos droits, Les Adhérents peuvent adresser leur demande à l’adresse courriel suivante :
                  <a href="https://request.privacy-bosch.com/" target="_blank">https://request.privacy-bosch.com/</a> ou <br/><br/>


                  D.P.O. du Groupe Bosch <br /><br />
                  Sécurité de l’Information et des Données (C/ISP)<br /><br />
                  Robert Bosch GmbH<br /><br />
                  Postfach 30 02 20<br /><br />
                  70442 Stuttgart Germany<br /><br />
                  Courriel : DPO@bosch.com<br /><br />

                  En précisant l’objet de la demande « droit des personnes ».  <br /><br />
                  Pour nous signaler des incidents liés à la protection des données, veuillez utiliser le lien suivant : <br /><br />
                 <a href="https://www.bkms-system.net/bosch-datenschutz" target="_blank">https://www.bkms-system.net/bosch-datenschutz.</a><br /><br />
                </p>
                <p><b>Article 15. Droit applicable et litige</b></p>
                <p>Le présent Règlement est soumis au droit français.  <br /><br />
                  En cas de litige relatif au présent Règlement, les parties s’engagent à tenter de résoudre amiablement leur litige.
                  A cet effet, la partie la plus diligente adressera à l’autre partie une lettre recommandée avec accusé de réception faisant état du différend et les parties se réuniront dans un délai
                  d’un (1) mois à compter de la réception de ladite lettre pour discuter des conditions et modalités susceptibles de permettre une résolution amiable du litige. <br/><br/>
                  Si dans un délai de trois (3) mois, les parties ne parviennent pas à résoudre amiablement leur différend, le tribunal de commerce de Paris sera compétent.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</main>
