<div class="compteClient">
  <ng-container *ngIf="userInfo$ | async as userInfo">
    <div class="name">{{userInfo.organisationName}}</div>
    <div class="adherentText">N° adhérent : <span class="adherentNumero">{{userInfo.adherentNumber}}</span></div>
  </ng-container>
  <ng-container *ngxPermissionsOnly="['ChefEntreprise']">
    <div class=numberPoints *ngIf="participant$ | async as participant">{{participant.points}}<span class="points"> points</span></div>
  </ng-container>
  <div class="link"><a [routerLink]="['/account/info']">Mes informations personnelles</a></div>
  <div class="link" *ngxPermissionsOnly="['ChefEntreprise']"><a [routerLink]="['/account/declaration']">Mes déclarations</a></div>
  <div class="link" *ngxPermissionsOnly="['ChefEntreprise']"><a [routerLink]="['/account/point']">Mes points</a></div>
</div>
