<main [ngSwitch]="status$ | async">
  <ng-container *ngSwitchCase="status.Invalid">
    <div class="compteGestionHeader">
      <div class="titre">Votre compte Degré +</div>
      <div class="subtitle"> Merci de vérifier et / ou compléter le formulaire suivant afin de pouvoir créer votre
        compte
        Degré + </div>
    </div>
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" novalidate>
      <div class="row">
        <div class="col-sm-6 compteGestion">
          <div class="titre">Votre identité</div>
          <div class="data">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Civilité*</label>
              <div class="col-sm-7">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="civility" id="inlineRadiomr" value="Monsieur"
                    required formControlName="civility"
                    [ngClass]="{'is-invalid': civility.invalid && (!civility.pristine || submitted) }">
                  <label class="form-check-label" for="inlineRadiomr">Monsieur</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="civility" id="inlineRadiomme" value="Madame"
                    formControlName="civility" required
                    [ngClass]="{'is-invalid': civility.invalid && (!civility.pristine || submitted) }">
                  <label class="form-check-label" for="inlineRadiomme">Madame</label>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputName" class="col-sm-4 col-form-label">Nom*</label>
              <div class="col-sm-7">
                <input type="text" class="form-control" id="inputName" required formControlName="lastName"
                  [ngClass]="{'is-invalid': lastName.invalid && (!lastName.pristine || submitted) }">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputFirstName" class="col-sm-4 col-form-label">Prénom*</label>
              <div class="col-sm-7">
                <input type="text" class="form-control" id="inputFirstName" required formControlName="firstName"
                  [ngClass]="{'is-invalid': firstName.invalid && (!firstName.pristine || submitted) }">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputMail" class="col-sm-4 col-form-label">Adresse email*</label>
              <div class="col-sm-7">
                <input type="text" readonly class="form-control-plaintext" id="inputMail" formControlName="email">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPhone" class="col-sm-4 col-form-label">Téléphone portable*</label>
              <div class="col-sm-7">
                <input type="text" class="form-control" id="inputPhone" required formControlName="phone"
                  [ngClass]="{'is-invalid': phone.invalid && (!phone.pristine || submitted) }">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputFunction" class="col-sm-4 col-form-label">Votre fonction*</label>
              <div class="col-sm-7">
                <select class="custom-select" formControlName="function" id="inputFunction" required
                  [ngClass]="{'is-invalid': function.invalid && (!function.pristine || submitted) }">
                  <option *ngFor="let function of functions" [ngValue]="function">{{function}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputRole" class="col-sm-4 col-form-label">Rôle*</label>
              <div class="col-sm-7">
                <select class="custom-select" formControlName="role" id="inputRole" required
                  [ngClass]="{'is-invalid': role.invalid && (!role.pristine || submitted) }">
                  <option *ngFor="let role of roles | keyvalue" [ngValue]="role.key">{{role.value}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="mentionRight col-sm-2">
          <p>Le site internet du Club Degré+ est un site associé à elmLeCube.fr </p>
          <p>L'adresse email de votre compte Degré + doit être celle utilisée pour votre compte elmLeCube.fr</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 compteGestion">
          <div class="titre">Votre société</div>
          <div class="data">

            <fieldset [disabled]="!canEditOrga || orgaLoading">
              <div class="form-group row">
                <label for="inputSiret" class="col-sm-4 col-form-label">SIRET*</label>
                <div class="col-sm-7">
                  <input type="text" class="form-control" id="inputSiret" required formControlName="siret"
                    [ngClass]="{'is-invalid': siret.invalid && (!siret.pristine || submitted) }"
                    placeholder="14 chiffres">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                    *ngIf="orgaLoading"></span>
                </div>
              </div>
              <div class="form-group row">
                <label for="inputCompany" class="col-sm-4 col-form-label">Appellation*</label>
                <div class="col-sm-7">
                  <input type="text" class="form-control" id="inputCompany" formControlName="company"
                    [ngClass]="{'is-invalid': company.invalid && (!company.pristine || submitted) }">
                </div>
              </div>
              <div class="form-group row">
                <label for="inputDescription" class="col-sm-4 col-form-label">Description</label>
                <div class="col-sm-7">
                  <input type="text" class="form-control" id="inputDescription" formControlName="companyDescription"
                    [ngClass]="{'is-invalid': description.invalid && (!description.pristine || submitted) }">
                </div>
              </div>
              <div class="form-group row">
                <label for="inputAddress1" class="col-sm-4 col-form-label">N° et nom de voie*</label>
                <div class="col-sm-7">
                  <input type="text" class="form-control" id="inputAddress1" formControlName="address1"
                    [ngClass]="{'is-invalid': address1.invalid && (!address1.pristine || submitted) }">
                </div>
              </div>
              <div class="form-group row">
                <label for="inputAddress2" class="col-sm-4 col-form-label">Complément</label>
                <div class="col-sm-7">
                  <input type="text" class="form-control" id="inputAddress2" formControlName="address2"
                    [ngClass]="{'is-invalid': address2.invalid && (!address2.pristine || submitted) }">
                </div>
              </div>
              <div class="form-group row">
                <label for="inputLocality" class="col-sm-4 col-form-label">Lieu-dit / CEDEX / BP</label>
                <div class="col-sm-7">
                  <input type="text" class="form-control" id="inputLocality" formControlName="locality"
                    [ngClass]="{'is-invalid': locality.invalid && (!locality.pristine || submitted) }">
                </div>
              </div>
              <div class="form-group row">
                <label for="inputZip" class="col-sm-4 col-form-label">Code postal*</label>
                <div class="col-sm-7">
                  <input type="text" class="form-control" id="inputZip" formControlName="zip"
                    [ngClass]="{'is-invalid': zip.invalid && (!zip.pristine || submitted) }">
                </div>
              </div>
              <div class="form-group row">
                <label for="inputCity" class="col-sm-4 col-form-label">Ville*</label>
                <div class="col-sm-7">
                  <input type="text" class="form-control" id="inputCity" formControlName="city"
                    [ngClass]="{'is-invalid': city.invalid && (!city.pristine || submitted) }">
                </div>
              </div>
            </fieldset>
            <div class="form-group row">
              <label for="inputEmployees" class="col-sm-4 col-form-label">Nbre employés*</label>
              <div class="col-sm-7">
                <input type="text" class="form-control" id="inputEmployees" formControlName="employees"
                  [ngClass]="{'is-invalid': employees.invalid && (!employees.pristine || submitted) }">
              </div>
            </div>
          </div>
        </div>
        <div class="mentionRight col-sm-2">
          <p *ngIf="unexistingSiretError">Le SIRET n'existe pas</p>
          <p *ngIf="existingSiretError">Le SIRET existe déjà</p>
          <p *ngIf="rejected">
            Attention : vous n'avez pas accès à cet outil.
            Le Club Degré+ est un programme de fidélité réservé exclusivement aux professionnels indépendants (installateurs et SAV).
            Pour plus d'informations, vous pouvez nous écrire sur communication.contact@fr.bosch.com.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 compteGestion">
          <div class="titre">Votre activité</div>
          <div class="data">
          <fieldset [disabled]="orgaLoading">

              <div class="form-group row">
                <label for="inputActivity" class="col-sm-4 col-form-label">Vous êtes*</label>
                <div class="col-sm-7">
                  <select class="custom-select" formControlName="activity" id="inputActivity"
                    [ngClass]="{ 'is-invalid': activity.invalid && (!activity.pristine || submitted) }"
                    (change)="checkForSav()">
                    <option *ngFor="let activity of activities" [ngValue]="activity">{{activity}}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row" >
                <label for="inputJob" class="col-sm-4 col-form-label">Métier*</label>
                <div class="col-sm-7">
                  <select class="custom-select" formControlName="job" id="inputJob"
                    [ngClass]="{ 'is-invalid': job.invalid && (!job.pristine || submitted) }">
                    <option *ngFor="let job of jobs" [ngValue]="job">{{job}}</option>
                  </select>
                </div>
              </div>
              <div class="form-row">
                <div class="col-sm-8">
                  Réalisez-vous le SAV de vos installations ?*
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12 center mt-3">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="doSav" id="inlineRadio1" [value]="true"
                      formControlName="doSav"
                      [ngClass]="{ 'is-invalid': doSav.invalid && (!doSav.pristine || submitted) }">
                    <label class="form-check-label" for="inlineRadio1">Oui</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="doSav" id="inlineRadio2" [value]="false"
                      formControlName="doSav"
                      [ngClass]="{ 'is-invalid': doSav.invalid && (!doSav.pristine || submitted) }">
                    <label class="form-check-label" for="inlineRadio2">Non</label>
                  </div>
                </div>
              </div>
            </fieldset>

          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 compteGestion">
          <div class="titre">Gestion des données personnelles</div>
          <div class="data">
            <div class="form-row col-sm-10">Oui, je reconnais avoir pris connaissance de la politique de
              confidentialité. <a href="">Politique de confidentialité</a></div>
            <div class="form-group row">
              <div class="col-sm-12 center mt-3">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="acceptConfidentiality" id="inlineRadio3"
                    [value]="true" formControlName="acceptConfidentiality"
                    [ngClass]="{ 'is-invalid': acceptConfidentiality.invalid && (!acceptConfidentiality.pristine || submitted) }">
                  <label class="form-check-label" for="inlineRadio3">Oui</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="acceptConfidentiality" id="inlineRadio4"
                    [value]="false" formControlName="acceptConfidentiality"
                    [ngClass]="{ 'is-invalid': acceptConfidentiality.invalid && (!acceptConfidentiality.pristine || submitted) }">
                  <label class="form-check-label" for="inlineRadio4">Non</label>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="form-check col-sm-10 mt-5 ">
                <input class="form-check-input" type="checkbox" id="defaultCheck1" formControlName="sendInfo">
                <label class="form-check-label" for="defaultCheck1">
                  J'accepte de recevoir des informations <b>concernant le Club Degré +</b> de la part de Bosch et
                  elm.leblanc via
                  e-mail.
                </label>
              </div>
              <div class="mention col-sm-8">Vous pouvez révoquer ce consentement à tout moment à l'adresse <a
                  href="mailto:privacy.TTFR@bosch.com">privacy.TTFR@bosch.com</a></div>
            </div>

          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 asterix">*Les champs marqués d'un astérisques sont obligatoires</div>
      </div>
      <div class="row my-5">
        <div class="col-sm-3 offset-sm-3">
          <button type="submit" class="btn button" [disabled]="saving">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="saving"></span>
            Valider mon inscription
          </button>
        </div>
      </div>
    </form>
  </ng-container>
  <div class="compteGestionHeader" *ngSwitchCase="status.Unauthenticated">
    <div class="titre">Votre compte Degré +</div>
    <div class="subtitle"> Vous devez vous enregistrer avant de créer votre compte.</div>
  </div>
  <div class="compteGestionHeader" *ngSwitchCase="status.Pending">
    <div class="titre">Votre compte Degré +</div>
    <div class="subtitle"> Votre compte est en cours de création. </div>
  </div>
  <div class="compteGestionHeader" *ngSwitchCase="status.Valid">
    <div class="titre">Votre compte Degré +</div>
    <div class="subtitle"> Votre compte est déjà créé. </div>
  </div>
  <div class="compteGestionHeader" *ngSwitchCase="status.Deactivated">
    <div class="titre">Votre compte Degré +</div>
    <div class="subtitle"> Votre société n'est pas autorisée à accéder au Club Degré+. </div>
  </div>
  <div class="compteGestionHeader" *ngSwitchDefault>
    <div class="titre">Votre compte Degré +</div>
    <div class="spinner-border text-dark m-5" role="status"></div>
  </div>
</main>
