
    <main class="m-3">
      <div class="baniere mb-4 mb-lg-5 ml-lg-4">
        <div class="texte">Barème des produits</div>
      </div>
      <div class="container-fluid mx-lg-5 baremeContainer">
        <app-scoring-category *ngFor="let item of scoringItems$ | async" [item]="item"></app-scoring-category>
        <app-spinner message="Chargement du barème en cours ..." *ngIf="(isLoading$ | async)"></app-spinner>
        <div class="d-flex justify-content-center align-items-center h-50 flex-column" *ngIf="(error$ | async) as error">Erreur lors du chargement du barème.</div>
      </div>
    </main>
