<main class="home">
    <section style="font-family: 'BoschSansRegular',sans-serif;font-size: 14px;color: #005691;margin:5px;">
        <div class="container">
            <div class="title" style="font-size:30px;">Imprint</div>

            <div class="content row" style="margin-top:30px;">
                <section class="section fs-two-column-text ">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-sm-6 col-md-6">
                                <p><b>Nom et Adresse</b><br></p>
                                <p>elm.leblanc S.A.S.</p>
                                <p>124, 126 rue de Stalingrad</p>
                                <p>93711 Drancy</p>
                                <p>FRANCE<br><br></p>
                                <p><b>Direction</b><br><br>Frédéric AGAR (Président)</p>
                                <p>François VUILLAUME</p>
                                <p>Nicolas CASOETTO</p>
                                <p>Erik LUCET </p>
                            </div>
                            <div class="col-sm-6 col-md-6">
                                <p><b>Numéro de téléphone</b><br></p>
                                <p><a title="0 820 00 6000" class="has-icon icon-smartphone" href="tel:0 820 00 6000"
                                        target="_self"> 0 820 00 6000 </a> (Numéro surtaxé 0,12 €/min + prix d'un
                                    appel)<br><br></p>
                                <p><b>Adresse e-mail</b><br></p>
                                <p><a title="BoschelmLeblanc.ServiceClient@fr.bosch.com" class="has-icon icon-mail"
                                        href="mailto:BoschelmLeblanc.ServiceClient@fr.bosch.com">
                                        BoschelmLeblanc.ServiceClient@fr.bosch.com </a><br><br></p>
                                <p><b>Enregistrement</b><br></p>
                                <p>SIREN B 542 097 944</p>
                                <p>RCS Bobigny<br><br></p>
                                <p><b>Code TVA</b><br></p>
                                <p>FR 89 542 097 944</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </section>
</main>
