import { Injectable } from '@angular/core';
import { ApplicationPaths, ApplicationName } from 'src/app/core/api-authorization.constants';
//import { LogLevel, OpenIdConfiguration } from 'src/app/shared/lib/angular-auth-oidc-client/src/lib/angular-auth-oidc-client';
import { LogLevel, OpenIdConfiguration } from 'angular-auth-oidc-client';

@Injectable()
export class ConfigService {

  load(): Promise<OpenIdConfiguration> {

    return fetch(ApplicationPaths.ApiAuthorizationClientConfigurationUrl)
      .then(res => res.json())
      .then(customConfig => new Promise((resolve) => {
        const config = {
          configId: 'openIdConfig1',
          silentRenew: true,
          // max_id_token_iat_offset_allowed_in_seconds: 600,


          //responseType: "code",

          // commentez en fonction de ce qu'on veut
          //useRefreshToken: true,
          //silentRenewUrl: customConfig.redirectUrl.replace(/\/$/, '') + '/silent-renew.html',
          //silentRenewUrl: customConfig.redirectUrl,

          useRefreshToken: true,
          //silentRenewUrl: customConfig.redirectUrl.replace(/\/$/, '') + '/silent-renew.html',

          // tokenRefreshInSeconds: 10,
          //historyCleanupOff: true,
          //disableIdTokenValidation: true,

          //eagerLoadAuthWellKnownEndpoints: false,
          //storage: new CustomStorage(),
          historyCleanupOff: false,
          //tokenRefreshInSeconds: 10,
          //maxIdTokenIatOffsetAllowedInSeconds: 600,

            // disableIatOffsetValidation: true,
          //disableIdTokenValidation : true,
          //  autoUserInfo:false,
          //  startCheckSession:false,

          //renewTimeBeforeTokenExpiresInSeconds: 180,
          // storage: sessionStorage,
           maxIdTokenIatOffsetAllowedInSeconds: 7200,

          //  disableIatOffsetValidation: true,


            // maxIdTokenIatOffsetAllowedInSeconds: false,
              //disableRefreshIdTokenAuthTimeValidation: true,
              //enableIdTokenExpiredValidationInRenew: false,
          ...customConfig
        } as OpenIdConfiguration;
        if (customConfig.client_secret) {
          config.customParamsAuthRequest = {
            client_secret: customConfig.client_secret,
          };
        }
        resolve(config);
      }))
      .catch((error) => {
        console.error(`Could not load settings for '${ApplicationName}'`);
      });
  }
}
