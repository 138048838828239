import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
//import { OidcSecurityService } from 'src/app/shared/lib/angular-auth-oidc-client/src/lib/angular-auth-oidc-client';
import { iif, of } from 'rxjs';
import { shareReplay, switchMap, tap } from 'rxjs/operators';
import { RegistrationStatus } from 'src/app/shared/models/registration-status.model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private userService: UserService, private oidcSecurityService: OidcSecurityService) { }

  getStatus() {
    return this.oidcSecurityService.isAuthenticated$.pipe(
      switchMap(response => !response.isAuthenticated ? of(RegistrationStatus.Unauthenticated) : this.userService.status$),
      shareReplay(1)
    );
  }
}
