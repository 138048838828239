import { Component, OnInit, Input } from '@angular/core';
import { ScoringFamily, ScoringBrand } from '../models/scoring-family.model';

@Component({
  selector: 'app-scoring-family',
  templateUrl: './scoring-family.component.html',
  styleUrls: ['./scoring-family.component.scss']
})
export class ScoringFamilyComponent implements OnInit {
  @Input() family: ScoringFamily;

  brand = ScoringBrand;

  constructor() { }

  ngOnInit(): void {
  }

}
