import { Component, OnInit, ViewChild, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
//import { OidcSecurityService } from 'src/app/shared/lib/angular-auth-oidc-client/src/lib/angular-auth-oidc-client';
import { Observable, of, zip } from 'rxjs';
import { Cart } from 'src/app/shared/models/cart.model';
import { CartService } from '../../services/cart.service';
import { catchError, map, mapTo, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { RegistrationStatus } from 'src/app/shared/models/registration-status.model';
import { RegistrationService } from '../../services/registration.service';
import { PermissionService } from '../../services/permission.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit, AfterViewInit {

  @ViewChild('stickyMenu') menuElement: ElementRef;

  sticky = false;
  elementPosition: any;

  isAuthAndValid$: Observable<boolean>;
  isBoss$: Observable<boolean>;
  isLoading$: Observable<boolean>;

  cart$: Observable<Cart>;

  public isMenuCollapsed = true;

  constructor(private cartService: CartService, private registrationService: RegistrationService,
    private permissionService: PermissionService) { }

  ngOnInit(): void {
    this.cart$ = this.cartService.cart$;

    this.isAuthAndValid$ = this.registrationService.getStatus().pipe(
      map(status => status === RegistrationStatus.Valid),
      shareReplay(1)
    );

    this.isLoading$ = this.isAuthAndValid$.pipe(mapTo(false), catchError(() => of(undefined)), startWith(true));

    this.isBoss$ = this.isAuthAndValid$.pipe(
      switchMap(isValid => isValid ? this.permissionService.hasPermission('ChefEntreprise') : of(false)),
      shareReplay(1)
    );
  }

  ngAfterViewInit() {
    this.elementPosition = this.menuElement.nativeElement.parentElement.offsetTop;
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    this.sticky = window.pageYOffset >= this.elementPosition;
  }
}
