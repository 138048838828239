import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { Routes } from '@angular/router';
import { ApplicationPaths } from './api-authorization.constants';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';

export const authRoutes: Routes = [
  { path: ApplicationPaths.Unauthorized, component: UnauthorizedComponent },
  { path: ApplicationPaths.Login, component: LoginComponent },
  // { path: ApplicationPaths.LoginFailed, component: LoginComponent },
  { path: ApplicationPaths.LoginCallback, redirectTo: '/' },
  { path: ApplicationPaths.LogOutCallback, component: LogoutComponent },
  // { path: ApplicationPaths.LogOut, component: LogoutComponent },
  // { path: ApplicationPaths.LoggedOut, component: LogoutComponent },
  // { path: ApplicationPaths.LogOutCallback, component: LogoutComponent }
];
