export const carouselContent: any[] = [
  {
    imageUrl: "assets/images/ISH_Visual_Female_Installer_At_Desktop.jpg",
    titles: ["Votre fidélité récompensée à sa juste valeur !"],
    texts: [
      "Le Club Degré+ c'est l'outil de deux marques, Bosch et elm.leblanc, qui s'allient pour récompenser votre fidélité et vous offrir le plein d'avantages !",
      "Plus vous déclarez de produits, plus vous bénéficiez d'avantages..."
    ],
  },
  //{
  //  imageUrl: "assets/images/cliamtisation-banniere.png",
  //  titles:[
  //    "Du 1er avril au 31 juillet",
  //      "Multipliez vos points fidélité par 2 grâce à la gamme climatisation !"
  //  ]

  //  ,
  //  texts: [

  //      "Déclarez vos installations de produits éligibles et obtenez des points supplémentaires sur votre compte Club Degré+"
  //  ],
  //  button:"Je déclare mon produit",
  //  url:"https://www.declarationappareil.elmlecube.fr/declaration/installation-declaration"

  //},
  /*{
    imageUrl: "assets/images/jeu-concours-banniere.png",
    titles: ["Du 1er avril au 31 août" ,
   " 6 voitures électriques AMI de Citröen à gagner avec le Club Degré+"]
    ,
    texts: [

        "Cochez la participation au Grand Jeu Concours à chacune de vos installations de climatisations.",
        "Vous êtes éligible à partir de 2 déclarations d’installation, voir conditions de l’offre sur notre site",
        "100% électrique | Sans permis | Silencieuse et zéro émission de CO²"
    ],
    button:"Je tente ma chance !",
    url:"https://www.declarationappareil.elmlecube.fr/declaration/installation-declaration"

  },*/
  {
    imageUrl: "assets/images/Banniere_accueil_OP_climatisation_3_avril_au_31_aout_2023.jpg",
    titles: [""],
    texts: [
      "",
      ""
    ],
  },
  {
    imageUrl: "assets/images/destockage-banniere.png",
    titles: ["Destockage de la boutique cadeaux Club Degré+"],
    texts: [
      "Vous avez des points disponibles ?",
      "Une sélection de produits vous y attend dans la boutique jusqu’à épuisement, après il sera trop tard…"
    ],
    button: "J’y vais !",
    url: "https://www.degreplus.elmlecube.fr/shop/category/Destockage/Destockage"
  },

]
