import { Component, OnInit, Input } from '@angular/core';
import { DebService } from 'src/app/core/services/deb.service';
import { Observable } from 'rxjs';
import { ParticipantInfo } from 'src/app/shared/models/participant-info.model';
import { UserInfo } from 'src/app/shared/models/user-info.model';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-compte-client',
  templateUrl: './compte-client.component.html',
  styleUrls: ['./compte-client.component.scss']
})

export class CompteClientComponent implements OnInit {

  //@Input() showPoint: boolean;

  participant$: Observable<ParticipantInfo>;
  userInfo$: Observable<UserInfo>;

  constructor(private debService: DebService, private userService: UserService) { }

  ngOnInit(): void {
    this.participant$ = this.debService.participant;
    this.userInfo$ = this.userService.getUserInfos();
  }

}
